import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'
import { Flex, Link, ListItem, OrderedList, Text } from '@chakra-ui/react'

export default function FraudDetection() {
  return (
    <div>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          FRAUD DETECTION POLICY
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          INTRODUCTION
        </Text>
        <Text>
          The Insurance Development and Regulatory Authority of India (IRDAI), vide its Guidelines
          on Insurance e-commerce bearing number IRDA/INT/GDL/ECM/055/03/2017 dated 9th March 2017
          mandates a pro-active fraud detection policy for the insurance e-commerce activities which
          is approved by the Board of Directors of the Company. Accordingly, the policy has been
          formulated considering frauds including e commerce fraud that the company can be exposed
          to. The policy shall provide guidance with respect to prevention, detection, investigation
          and mitigation into fraudulent activities related to e-commerce.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          OBJECTIVE
        </Text>
        <Text>
          The Policy is established to prevent, detect, investigate and mitigate the insurance fraud
          in the Company. It would facilitate development of processes to prevent, detect and manage
          frauds. Further, it will also ensure development of control measures at an organizational
          level and conducting investigations. The Company is committed to conducting business in an
          environment of fairness and integrity and will strive to eliminate fraud from all
          operations. The Company adopts a “Zero-Tolerance” approach to fraud and will not accept
          any dishonest or fraudulent act committed by internal and external stakeholders.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          APPLICABILITY
        </Text>
        <Text>
          The Policy applies to any fraud or suspected fraud involving its officials and employees,
          shareholders, vendors, contractor, business associates, policyholders, assignees,
          claimants, nominees and outside agencies doing business with the company or any other
          parties having relationship with the company. Any investigation activity required, will be
          conducted irrespective of the suspected wrongdoer’s length of services,
          position/title/designation, or relationship with the company.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          CLASSIFICATION OF INSURANCE FRAUDS
        </Text>
        <Text>
          IRDAI&apos;s circular bearing IRDAI/SDD/MISC/CIR/009/01/2013 describes fraud in insurance
          as an act or omission intended to gain dishonest or unlawful advantage for a party
          committing the fraud or for other related parties. This may, for example, be achieved by
          means of:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>Misappropriating assets.</ListItem>
          <ListItem>
            Deliberately misrepresenting, concealing, suppressing or not disclosing one or more
            material facts relevant to the financial decision, transaction or perception of the
            Company&apos;s status.
          </ListItem>
          <ListItem>
            Abusing responsibility, a position of trust or a fiduciary relationship
          </ListItem>
        </OrderedList>
        <Text marginTop={2}>
          In order to adequately protect the company from the financial and reputational risks posed
          by insurance frauds, the policy is designed to prevent, detect, investigate and mitigate
          occurrence of frauds in the company. The policy includes measures to protect the Company
          from the threats posted by the following broad categories of frauds with illustrative
          list.
        </Text>
        <Text fontWeight={'medium'} marginTop={2}>
          a. Internal Fraud: Fraud/misappropriation against the Company by its Director, Manager,
          employee and/or anyone else.
        </Text>
        <Text marginTop={2}>Illustrative list</Text>
        <Text marginTop={1} marginBottom={1}>
          The list is only illustrative and not exhaustive:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Embezzlement (i.e. misappropriation of money, securities, supplies, property or other
            assets);
          </ListItem>
          <ListItem>
            Fraudulent financial reporting (e.g. forging or alteration of accounting documents or
            records.)
          </ListItem>
          <ListItem>
            Cheque fraud (i.e. forgery or alteration of cheques, bank drafts or any other financial
            instrument.)
          </ListItem>
          <ListItem>
            Overriding decline decisions so as to open accounts for family and friends
          </ListItem>
          <ListItem>inflating expenses claims/over billing</ListItem>
          <ListItem>
            paying false (or inflated) invoices, either self-prepared or obtained through collusion
            with suppliers
          </ListItem>
          <ListItem>
            permitting special prices or privileges to customers, or granting business to favoured
            suppliers, for kickbacks/favours
          </ListItem>
          <ListItem>
            Forgery or alteration of documents or accounts belonging to the Company
          </ListItem>
          <ListItem>
            Conflicts of Interest resulting in actual or exposure to financial loss.
          </ListItem>
          <ListItem>Payroll fraud.</ListItem>
          <ListItem>Tax evasion.</ListItem>
          <ListItem>
            Unauthorized or illegal use of confidential information (e.g. profiteering as a result
            of insider knowledge of company activities);
          </ListItem>
          <ListItem>
            Unauthorized or illegal manipulation of information technology networks or operating
            systems.
          </ListItem>
          <ListItem>
            Intentional failure to record or disclose significant information accurately or
            completely);
          </ListItem>
          <ListItem>Improper pricing activity.</ListItem>
        </OrderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          b. Policyholder Fraud: Fraud against the Company in the purchase and/or execution of an
          insurance product, including fraud at any time during the term of the policy.
        </Text>
        <Text marginTop={2}>Illustrative List</Text>
        <Text marginTop={1} marginBottom={1}>
          The list is only illustrative and not exhaustive:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>Staging the occurrence of incidents</ListItem>
          <ListItem>Reporting and claiming of fictitious damage/loss</ListItem>
          <ListItem>Insurer reported medical claims fraud</ListItem>
          <ListItem>Fraudulent Death Claims</ListItem>
          <ListItem>
            Unauthorized transactions being initiated on policies such as switches, withdrawals,
            surrenders etc
          </ListItem>
          <ListItem>Unauthorized changes in contact details</ListItem>
          <ListItem>
            Cash, cheques handed over by policyholders to agents however, they have not received any
            intimation from the company of its receipt
          </ListItem>
        </OrderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          c. Third party Frauds
        </Text>
        <Text marginTop={2}>Illustrative List</Text>
        <Text marginTop={1} marginBottom={1}>
          The list is only illustrative and not exhaustive:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Fake or forged receipts and/or policy documents issued by third parties
          </ListItem>
          <ListItem>
            Spurious calls by third parties to customers promising them inflated returns for
            purchasing new policies or on surrender of their existing policies
          </ListItem>
        </OrderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          Online Fraud - This type of fraud is typically a third-party fraud; however, this could
          involve any of the following types of frauds
        </Text>
        <Text marginTop={2}>Illustrative list</Text>
        <Text marginTop={1} marginBottom={1}>
          The list is only illustrative and not exhaustive:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Buyers filings fraudulent claims or making premium payments using compromised payment
            cards
          </ListItem>
          <ListItem>
            Merchant side frauds: Frauds committed by any of the merchant partners of the Company
            which would include non-remittance of premium collected on behalf of the Company and/or
            incorrect charge backs etc
          </ListItem>
          <ListItem>
            Cyber security frauds: Transactions effected through fake or stolen credit card/bank
            accounts to carry out a transaction in the web portal of the Company.
          </ListItem>
          <ListItem>
            Data leakage: Threat of confidential data of the Company being comprised due to any
            cyber-attack/hacking of the Company systems
          </ListItem>
          <ListItem>Fraud committed by POSP (Point of Sales Person)</ListItem>
          <ListItem>
            Other Frauds: Phishing emails sent to customers promising them inflated returns. Using
            social engineering techniques to wrongly influence the customers to share their identity
            details
          </ListItem>
        </OrderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          COMPOSITION OF FRAUD INVESTIGATION UNIT
        </Text>
        <Text>
          The Fraud Investigation Unit shall be head by Principal Officer who shall, basis the
          nature of fraud under investigation, include employees from different units on ad-hoc
          basis for immediate support and assistance.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          PROCEDURES
        </Text>
        <Text>
          All functional head are primarily responsible for day to day management of activities and
          in charge of maintaining, implementing and improving their system and control so that they
          minimise the risk of fraud.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          IDENTIFICATION AND REPORTING OF FRAUD AND PROSPECTIVE FRAUD
        </Text>
        <Text>
          All the frauds detected by any department/or detected by any person with knowledge of
          confirmed, attempted or suspected fraud or any person who is personally being placed in a
          position by other person to participate in the fraudulent activity shall be reported to
          and by the functional head within 48 working hours from the detection of any confirmed,
          attempted, or suspected fraud.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          REPORTING MECHANISM
        </Text>
        <Text>
          The Fraud Investigation Unit is entrusted with the responsibility to examine and
          investigate the reported frauds. Any fraud detected by any person should be reported to
          the Fraud Investigation Unit as follows:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            by email to Fraud Investigation Unit at{' '}
            <Link as='a' href='compliance@zopperinsurance.com' color='blue.500'>
              compliance@zopperinsurance.com,
            </Link>{' '}
            or
          </ListItem>
          <ListItem>
            by letter marked “Private and Confidential” and address to Fraud Investigation Unit
            Zopper Insurance Brokers Private Limited, Corporate Office - 7th Floor, Plot no 1, BGR
            Tower, Sector 16 A , NOIDA U.P. 201301.
          </ListItem>
        </OrderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          1. Moreover, the person also have the right to make fraud detection information directly
          to the Principal Officer of the Company as follows:
        </Text>
        <OrderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            a. by email to{' '}
            <Link as='a' href='Po@zopperinsurance.com' color='blue.500'>
              Po@zopperinsurance.com;
            </Link>{' '}
            or
          </ListItem>
          <ListItem>
            b. by letter marked “Private and Confidential” and addressed to: The Principal Officer,
            Zopper Insurance Brokers Private Limited Corporate Office – 7th Floor, Plot no 1, BGR
            Tower, Sector 16 A , NOIDA U.P. 201301.
          </ListItem>
        </OrderedList>
        <Text fontWeight={''} marginTop={2}>
          In case, the person have fraud detection information against senior officers of the
          Company, they may directly reach out the Board of Directors of the Company by letter
          marked “Private and Confidential” and addressed to: The Board of Directors - Zopper
          Insurance Brokers Private Limited Corporate Office – 7th Floor, Plot no 1, BGR Tower,
          Sector 16 A , NOIDA U.P. 201301.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          INVESTIGATION RESPONSIBILITIES
        </Text>
        <Text>
          The, Principal Officer, Compliance Officer, or any other authorised person as the case may
          be, is entrusted with the full authority for the investigation of all suspected/actual
          fraudulent acts as defined in this policy. He will take the necessary support from all
          concerned departments, external outsourced investigation agencies, and forensic experts,
          etc for investigation, if required. Moreover, the PO has the power to form a team from
          case to case basis and such investigation team will be given all the rights, authority to
          investigate, any company’s books, desk, cabinets, storage, emails, files or access, to any
          premises etc., whatsoever to investigate the case. The Legal Team shall initiate
          proceedings in case of Fraud where ever required.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          AWARENESS
        </Text>
        <Text>
          Employees are regularly given trainings covering Anti Money Laundering, Anti-Bribery and
          Corruption etc. Awareness amongst employees is also created through regular circulars,
          communication by the leaders via e-mail and at town halls/meetings etc.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          REVIEW
        </Text>
        <Text>
          The policy will be reviewed by the Board of Directors of the Company as and when required.
        </Text>
      </Flex>
      <Footer />
    </div>
  )
}
