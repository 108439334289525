import { Flex, Text } from '@chakra-ui/react'
import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'

export default function CancellationRefund() {
  return (
    <Flex minH={'100vh'} flexDir={'column'}>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Refund Policy
        </Text>
        <Text>
          In case of a refund due to an erroneous transaction or cancellation, the premium paid for
          an insurance product will be refunded through direct credit or cheque as per the
          prevailing policy of the respective Insurance Company that you have chosen to buy the
          product from.
        </Text>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Cancellation Policy
        </Text>
        <Text>
          In case of a cancellation, the premium paid for an insurance product will be refunded
          through direct credit or cheque as per the policy of the respective Insurance Company that
          you have chosen to buy the product from.
        </Text>
      </Flex>
      <Flex marginTop={'auto'}>
        <Footer />
      </Flex>
    </Flex>
  )
}
