const API_ENDPOINTS = {
  LOGIN: '/accounts/login',
  LOGOUT: '/accounts/logout',
  SEND_OTP: '/otp/send',
  VERIFY_OTP: '/otp/verify',
  // USER_LISTING: '/accounts/userlisting',
  // Quotations Page
  ADD_ONS: '/policy/addons',
  ADDITIONAL_COVERS: '/policy/additionalcovers',
  MOTOR_QUOTATIONS: '/policy/quotation/v2',
  PLAN_DETAILS: '/policy/details',
  AUTH: '/accounts/authtoken',
  MANUFACTURERS: '/dropdown/manufacturer',
  MODELS: '/dropdown/model',
  VARIANTS: '/dropdown/variant',
  INSURERS: '/insurer/listing',
  RTO: '/dropdown/rto',
  CPA_CHECK: '/policy/cpa',
  VAHAN_CHECK: '/policy/quote-vahan-details',
  OFFLINE_UPLOAD: '/policy/offline-upload',
  PINCODE_CMS: '/autofill/pincode',
  OCCUPATIONS: '/mapping/occupations',
  RELATIONSHIPS: '/mapping/relationships',
  MOTOR_PROPOSAL: '/policy/proposal',
  CKYC_VALIDATE: '/policy/CkycValidateInsurer',
  CKYC_NUMBER: '/policy/GetCkycNumber',
  IMAGE_RC_OCR: '/policy/prefill-data-rc',
  IMAGE_POLICY_OCR: '/policy/prefill-data-policy',
  PAYMENT_CHECK: '/policy/paymentcheck',
  HYPOTHECATION: '/policy/miscellaneous',
  // health
  HEALTH_QUOTATION: '/common/quotation',
  HEATH_ADDONS: '/common/addons-riders',
  HEALTH_PINCODE: '/common/pincode',
  HEALTH_SUMINSURED_GENRIC: '/common/list-suminsured',
  HEALTH_INSURER_LISTING: '/insurer/list-insurer',
  HEALTH_INSURANCE_LISTING: '/insurer/list-insurer-plans',
  HEALTH_SALES_BANK: '/policy/report',

  HEALTH_QUES: '/common/question',
  HEALTH_LIST_MISCELLANEOUS: '/common/list-miscellaneous',
  HEALTH_BENEFITS: '/insurer/list-benifits',
  HEALTH_PROPOSAL: '/common/proposal',
  // MOTOR
  MOTOR_SALES_BANK: '/policy/report',
  MOTOR_SALES_DOWNLOAD: '/policy/report/download',
  // GTL
  GTL_PROPOSAL: '/group/term/proposal',
  GTL_PLANS: '/group/term/plans',
  GTL_POLICY_BOOKING: '/group/term/policy-booking',
  // GPA
  GPA_PROPOSAL: '/group/personal-accident/plan_creation',
  GPA_QUOTE_PLANS: '/quote/plans',
  GPA_POLICY_BOOKING: '/group/personal-accident/policy_booking',
  GPA_PLANS: '/group/personal-accident/plans',

  GROUP_MAPPING: '/group/term/mapping',
  GROUP_PROPOSAL: '/proposal/',
  HOSPICASH_MAPPING: '/core/fetch-map',
  HOSPICASH_POLICY: '/policy/',
  USER_LISTING: '/accounts/users',
  POST_USER_LISTING: '/accounts/user',
  USER_LISTING_PINCODE: '/locations',
  UPDATE_PASSWORD: '/accounts/users/2882',
  VEHICLE_USAGE_TYPE: '/dropdown/vehicle-usage-type',
  PRODUCT_TYPE: '/dropdown/manufacturer/product-type',
  SUBMIT_API: '/policy/offline-upload',

  // PAYOUT
  SALES_OVERVIEW: '/services/v1/policy-dashboard',
  POLICY_UPLOAD: '/services/v2/policy-upload',
  RECENTLY_UPLOADED_FILES: '/services/v1/bulk-upload-list',
  DOWNLOAD_RECENTLY_UPLOADED_FILES: '/services/v1/download/',
  FAILED_REPORTS: '',
  CHILD_ID_LIST: '/services/v1/insurer-child-mappings',
  CHILD_MAP: '/services/v1/insurer-child-mappings',
  CHILD_REMOVE: '/services/v1/insurer-child-map-remove/',
  PAYOUT_UPLOAD: '/services/v2/payout-upload',
  UPLOAD_CASES: '/services/v2/policy-verify-upload',
  OFFLINE_SOLD_POLICY_DOWNLOAD: '/services/v1/policy-dashboard/download',
  POLICY_VERIFY: '/services/v1/policy-verify',
  CHILD_REPORT_DOWNLOAD: '/services/v1/child-mapping-report/download',
  INSURER_LISTING: '/services/v1/insurer-list',

  REPORT_LISTING: '/operations/motor-policy-data/',
}

export { API_ENDPOINTS }
