import { KeyboardEvent } from 'react'

export const IndianRupeeFormatFromString = (num: string) =>
  Intl.NumberFormat('en-IN').format(parseInt(num))

export const onlyAllowTypingNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
  if (!(e.key === 'Backspace' || e.key === 'Tab' || e.key === 'ArrowDown') || e.metaKey) {
    if (e.key.charCodeAt(0) < 48 || e.key.charCodeAt(0) > 57) {
      if (
        !(
          (e.key.toLowerCase() === 'c' && e.metaKey) ||
          (e.key.toLowerCase() === 'a' && e.metaKey) ||
          (e.key.toLowerCase() === 'v' && e.metaKey)
        )
      ) {
        e.preventDefault()
      }
    }
  }
}

export const openLinkInSpecifiedTab = (
  link: string,
  target: '_blank' | '_self' | '_parent' | '_top' | 'framename',
) => {
  const aTag: HTMLAnchorElement = document.createElement('a')
  aTag.href = link
  aTag.target = target
  aTag.click()
}
