import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

interface IToastProps {
  title: string
  message?: string
}

export const ToastService = {
  showSuccessTaost: ({ title, message }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      variant: 'solid',
      position: 'top-right',
      isClosable: true,
      status: 'success',
    })
  },
  showErrorToast: ({ title, message }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      variant: 'solid',
      position: 'top-right',
      status: 'error',
      isClosable: true,
    })
  },
  showInfoToast: ({ title, message }: IToastProps) => {
    toast.toast({
      title: title,
      description: message,
      variant: 'solid',
      position: 'top-right',
      status: 'info',
      isClosable: true,
      duration: 3000,
    })
  },
}
