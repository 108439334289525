import { Flex, Link, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react'
import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'

export default function TermsUse() {
  return (
    <Flex minH={'100vh'} flexDir={'column'}>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Terms of Use
        </Text>
        <Text>
          Zopper Insurance Brokers Private Limited (hereinafter referred to as “Zopper” or “We” or
          “Us” or “Our”) welcomes you at ‘www.zopperinsurance.com or mobile applications thereof
          accessible through various internet enabled smart devices (individually and collectively
          referred to as “Platform”) for providing access and choice to buy insurance products from
          various insurance companies, and related services and any offerings that are incidental
          and ancillary thereto (‘Services’), as available on the Platform. Zopper is not an
          insurance company but a licensed Insurance Broking Company holding a Broking licence from
          the Insurance Regulatory and Development Authority of India.
        </Text>
        <Text marginTop={2}>
          THESE TERMS OF USE (&quot;Terms Of Use&quot;) IS AN ELECTRONIC RECORD IN THE FORM OF AN
          ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE
          THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN
          VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS OF USE
          DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
        </Text>
        <OrderedList spacing={1} marginTop={2}>
          <ListItem>
            <Text textDecoration={'underline'}>Governing Document:</Text>
            <Text paddingLeft={5} marginTop={2}>
              1.1 The Platform is owned by Zopper Insurance Brokers Private Limited a company
              incorporated under the Companies Act, 2013 with its Corporate Office – 7th Floor, Plot
              no 1, BGR Tower, Sector 16 A, NOIDA U.P. 201301. These Terms of Use available at the
              Platform govern terms on which Zopper offers the guest users or registered users
              (hereinafter referred to as &quot;You&quot; or &quot;Your&quot; or
              &quot;Yourself&quot; or &quot;User&quot;) access to the Platform for availing
              Services. If You transact on the Platform, You shall be subject to the policies that
              are applicable to the Platform for such transaction. By mere use of the Platform, You
              shall be contracting with Zopper and these terms and conditions including the policies
              constitute Your binding obligations, with Zopper.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              1.2 We reserve the right, at Our sole discretion, to change, modify, add or remove
              portions of these Terms of Use, at any time without any prior written notice to You.
              It is Your responsibility to review these Terms of Use periodically for updates /
              changes. Your continued use of the Platform following the posting of changes will mean
              that You accept and agree to the revisions. As long as You comply with these Terms of
              Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to
              enter and use the Platform.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Acknowledgment and Acceptance:</Text>
            <Text paddingLeft={5} marginTop={2}>
              2.1 Entire Agreement: These Terms of Use together with below listed documents/policies
              (without limitation) available either at Platform or entered separately by Zopper with
              You, as applicable, and all other notices, rules, guidelines with respect to Your use
              of Platform, constitutes the entire agreement (“Agreement”) between Zopper and You.
            </Text>
            <UnorderedList paddingLeft={5} marginTop={2}>
              <ListItem>Privacy Policy</ListItem>
              <ListItem>Grievance Redressal</ListItem>
              <ListItem>Fraud Detection</ListItem>
              <ListItem>Shipping Policy</ListItem>
              <ListItem>Cancellation and Refund</ListItem>
              <ListItem>Disclaimer</ListItem>
            </UnorderedList>
            <Text paddingLeft={5} marginTop={2}>
              2.2 These additional documents/policies, wherever applicable, including terms imposed
              by mobile application stores like Apple’s iTunes, Android’s etc. (‘Additional Terms’)
              are deemed to be incorporated under these Terms of Use by way of reference.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              2.3 You acknowledge and agree that Your usage (defined below) of the Platform are
              strictly regulated and governed by the terms and conditions of this Agreement.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              2.4 PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OR REGISTERING ON THE
              PLATFORM, ACCESSING ANY MATERIAL, INFORMATION OR SERVICES, POSTING ANY
              ADVERTISMENT/INFORMATION AT OR THROUGH THE PLATFORM. IF YOU DO NOT AGREE WITH THESE
              TERMS OF USE, PLEASE DO NOT USE THE PLATFORM.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Eligibility:</Text>
            <Text paddingLeft={5} marginTop={2}>
              3.1 Services available through or use of the Platform is available only to persons of
              majority (18 years of age or more) and who can legally component to form a contract
              under the Indian Contract Act, 1872 (as amended from time to time), or any other act
              or enactments to which the person is subject.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              3.2 Persons who are incompetent, to make a legal and valid contract, for any reason
              whatsoever (minor, lunatic, insane, undischarged insolvent or otherwise) are not
              eligible to use Our Platform and avail Our Services. If You are under the age of 18
              years or are otherwise incompetent to enter into a legally binding contract, then You
              must use the Platform, application or Services under the supervision of Your parent,
              legal guardian, or any responsible adult.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              3.3 Further to this, Zopper reserves its right, without assigning any reason, to
              restrict or limit Your access to the Platform and can further terminate Your access to
              Platform and deny the Services available through or at Our Platform. This right of
              suspension / termination of Services of Zopper, is in addition to any other remedy
              available to Zopper, for access & usage of Platform or availing any of Our Services
              through Platform, which is in contravention of any of the terms and conditions of
              Terms of Use or this Agreement or any other applicable law.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Important:</Text>
            <Text paddingLeft={5} marginTop={2}>
              4.1 By accessing, browsing and using this Platform or availing Services, You agree and
              acknowledge that, You understand this limited and restricted use and accessing,
              browsing or using this Platform or availing any Services is out of your own free will,
              discretion and responsibility.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              4.2 Zopper reserves its right to modify, suspend, cancel or discontinue any or all
              sections, or Services at any time without any notice. You agree that Zopper shall not
              be liable to You or to any third party for any modification, suspension or
              discontinuance of the Platform.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              4.3 You release and indemnify Zopper and/or any of its officers and representatives
              from any cost, damage, liability or other consequence of any of the actions of the
              Users of the Platform and specifically waive any claims that you may have in this
              behalf under any applicable law. You acknowledge and agree that when You interact with
              Zopper through Platform or send any data/email/message or communicate otherwise, You
              are communicating with Zopper through electronic means and gives Your consent to
              receive communication through electronic means periodically and as and when required.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Services:</Text>
            <Text paddingLeft={5} marginTop={2}>
              5.1 On the Platform, Zopper provides Users with access to information primarily about
              insurance products and services including but not limited to general insurance, health
              insurance and life insurance products and related services (including but not limited
              to renewals). All information and material available on the Platform is only to assist
              the User of Platform who are interested in the Services provided by Zopper and who
              wants to explore more options before concluding their transactions.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.2 Any User, who avails of the Services after visiting Our Platform, does so solely
              at his own discretion, risk and responsibility. Zopper does not make any
              recommendations nor gives any warranty (implied or express), guarantee or otherwise
              with respect to the quality, functionality, fitness for a particular purpose.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.3 You acknowledge and agree that details available on the Platform is available for
              information purpose.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.4 When You avail of any of the Services after visiting the Platform, You shall alone
              be responsible to complete the documentation part as per applicable laws and Zopper
              shall not be responsible for any consequences (including without limitation,
              cancellation of transaction, incomplete or improper documentation) whatsoever.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.5 You hereby grant unconditional and unequivocal consent and authorize Zopper while
              acting on Your behalf for facilitating the transaction on the Platform, to make use of
              Your vehicle registration number or any other vehicle details as submitted by You on
              the Platform, to retrieve the information in relation to your motor vehicle or to
              confirm/ verify respective vehicle details from government or third party websites and
              applications through use of technological solutions or any other method itself or
              through third party service providers.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.6 If You require certain additional services, Zopper is not and shall not be obliged
              to provide any such additional services.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.7 Zopper strongly advises you not to test the Platform with false purchases request,
              as it may put you at substantial personal legal risk. It is a crime to use a false
              name, other false personal information to buy. Wilfully entering erroneous or
              fictitious purchase request may result in prosecution by Zopper and/or seller. Please
              be aware that even if you do not give Zopper, your real name, Your web browser
              transmits a unique address to Us, which can be used by law enforcement officials to
              identify You.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              5.8 All the Services are provided at the sole discretion of Zopper, and You
              acknowledges and agree that Zopper can add or provide additional services not listed
              above, or can remove / suspend / cancel any or all services/products, listed above
              without any notice or liability.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Your Account And Login Options:</Text>
            <Text paddingLeft={5} marginTop={2}>
              6.1 User may access the Platform or other information and material as available on the
              Platform, as a guest user, without any requirement of registration. Certain features
              and information are made available on the Platform for all users (called ‘Guest User’)
              who do not need to register or create any account on Platform. However, to avail other
              certain features, You may need to register / create an account by providing certain
              details e.g. Your name, contact details, email id etc. (“Your Information”).
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              6.2 Once You register on the Platform by providing Your Information through OTP
              authentication using Your mobile number or through any other mode, Zopper sets up an
              account (“Your Account”) which is unique to You. Subject to Your compliance with
              account creation guidelines and any other applicable law, rule, Zopper recognizes You
              as a registered user (Registered User) and You can login into Your Account and avail
              the Services.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              6.3 If You use or access Our Platform or avail any Services through or at Platform as
              a Registered User, You acknowledge and agree that You shall be solely responsible for
              maintaining the confidentiality and shall not disclose the details of Your Account to
              any other person and that any activity occurred under Your Account shall be Your
              responsibility.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              6.4 You agree that when You provide Your Information while registering/creating Your
              Account with Us, You shall not provide any information which is untrue, false,
              inaccurate, incomplete or not current and shall not use the details of any other
              person, or another user’s account. Zopper may suspend/delete Your Account or can
              limit/deny the access to the Platform or Services, any time, if Your Information is
              found to be untrue, false, inaccurate, not current or pertains to another user’s
              account.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              6.5 You agree that collection, storage, sharing or disclosure of Your Information that
              You provide to Us either as a guest user or Registered User or otherwise shall be
              governed by the Privacy Policy of Zopper, incorporated herein by way of reference.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              6.6 You expressly understand and agree that, to the maximum extent permitted by
              applicable law Zopper will not be liable for any loss that You may incur as a
              consequence of unauthorized use of your Account or Account information in connection
              with the Platform or any Services, either with or without Your knowledge.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>User Content:</Text>
            <Text paddingLeft={5} marginTop={2}>
              7.1 Being the originator of any information or material made available by You through
              the Platform (”User Content”), You are solely responsible for the User Content that
              You upload, post, publish, transmit or otherwise make available on the Platform.
              Though Zopper does not pre-screen the User Content Zopper shall have the right (but
              not the obligation) in their sole discretion to accept, refuse, move, or remove any
              User Contents that are available via the Platform.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              7.2 In case the User Content is in violates/infringes any patent, trademark, trade
              secret, copyright or any other proprietary or privacy rights of any third party or in
              contravention of any applicable law, then Zopper at its sole discretion may remove or
              disable the access to the User Content or any part thereof, without any notice to
              User.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              7.3 Assignment of User Content: You hereby grant Zopper a perpetual, non-revocable,
              worldwide, royalty-free and sub- licensable right and license to use, copy,
              distribute, display, publish, transmit, make available, reproduce, modify, adapt the
              User Content and create derivate works of the User Content. You represent and warrant
              that You own or otherwise control all of the rights to the User Content that You post
              or that You otherwise provide on or through the Platform; and that, as at the date
              that the User Content is posted or submitted on the Platform: (i) the User Content is
              accurate; (ii) use of the User Content does not breach these Terms of Use; and (iii)
              that such User Content is lawful.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              7.4 When You share any feedback or post any comment (including User Content) on
              Platform, it is deemed non- confidential. Zopper is at liberty to use any such
              feedback or User Content and You represent that: (i) Your feedback does not contain
              confidential or proprietary information of You or of third parties; (ii) Zopper is not
              under any obligation of confidentiality, express or implied, with respect to the
              feedback; (iii) Zopper may have something similar to the feedback already under
              consideration or in development; and (iv) You are not entitled to any compensation or
              reimbursement of any kind from Zopper for the feedback under any circumstances.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>User’s Obligation:</Text>
            <Text paddingLeft={5} marginTop={2}>
              8.1 Subject to the compliance with all applicable laws, rules and terms and conditions
              of this Agreement, Zopper grants the User a limited, non-exclusive,
              non-sub-licensable, non-transferable, revocable and limited permission to access and
              use the Platform and avail the Services provided through or at the Platform.
            </Text>
            <Text paddingLeft={8} marginTop={2}>
              8.2 You acknowledge, agree and undertake that Your use of the Platform shall be
              strictly governed by this Agreement and the following binding principles:
              <Text paddingLeft={8} marginTop={2}>
                8.2.1 You shall not use or access the Platform or avail the Services by any means
                other than through the interface that are provided by Zopper.
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.2 When You use the Platform or Platform and/or the Services You specifically
                undertake not to host, display, upload, modify, publish, transmit, update or share
                any User Content that:
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.1 belongs to another person and to which the User does not have any right
                  to;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.2 is grossly harmful, harassing, blasphemous, defamatory, obscene,
                  pornographic, paedophilic, libellous, invasive of another&apos;s privacy, hateful,
                  or racially, ethnically objectionable, disparaging, relating or encouraging money
                  laundering or gambling,;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.3 harms minors in any way;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.4 violate any applicable laws or regulations for the time being in force
                  within or outside India;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.5 deceives or misleads the addressee about the origin of such messages or
                  communicates any information which is grossly offensive or menacing in nature;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.6 impersonate another person or entity;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.7 contain software viruses or any other computer code, files or programs
                  designed to interrupt destroy or limit the functionality of any computer resource;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.8 threatens the unity, integrity, defence, security or sovereignty of India,
                  friendly relations with foreign states or public order or causes incitement to the
                  commission of any cognizable offence or events investigation of any offence or is
                  insulting of any other nation;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.9 is misleading in any way or shall not , directly or indirectly, offer,
                  attempt to offer, trade or attempt to trade in any item, the dealing of which is
                  prohibited or restricted in any manner under the provisions of any applicable law,
                  rule, regulation or guideline for the time being in force.
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.10 contains restricted or password-only access pages, or hidden pages or
                  images (those not linked to or from another accessible page);
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.11 relating or encouraging money laundering or gambling, or otherwise
                  unlawful in any manner whatever
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.12 interferes with another User&apos;s use and enjoyment of the Platform;
                </Text>
                <Text paddingLeft={8} marginTop={2}>
                  8.2.2.13 refers to any website or URL that, in Our sole discretion, contains
                  material that is inappropriate for the Platform or any other website;
                </Text>
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.3 You shall not engage in any activity that interferes with or disrupts access
                to the Platform or the Services available through or at the Platform and shall not
                attempt or involve in the transmission of “junk/phishing mail”, “chain emails /
                letters”, “spamming” or “unsolicited mass mailing”.
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.4 You shall not attempt to gain unauthorized access to any other user’s account,
                Platform any portion or Feature of the Platform, any other systems or networks
                connected to the Platform, or any computer resource / servers, of Zopper connected
                with the Platform to provide the Services. You shall not access the Platform,
                Features or any of the Services offered on or through the Platform, by hacking,
                password mining or any other illegitimate means or access or copy any Content or
                User Content on the Platform including any derivate thereof through manual and/or
                automated means including but not limited to page scraping, data mining, data
                gathering, indexing, using software, devices, bots, crawlers, spiders, scripts, deep
                links, browser plug-ins or breach/disregard/violate any limitations/instructions in
                robot.txt file if any embedded in Platform or URLs;
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.5 You shall not probe, scan or test the vulnerability of the Platform or any
                network connected to the Platform, nor disrupt, interfere, breach the security or
                authentication measures on the Platform or any network connected to the Platform or
                cause any harm to the Platform, system resources, servers of Zopper connected to or
                accessible through the Platform.
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.6 You may not reverse look-up, trace or seek to trace any information on any
                other user, of or visitor to, the Platform, or any other customer of Zopper,
                including any other account on the Platform not owned by You, to its source, or
                exploit the Platform or Service or information made available or offered by or
                through the Platform, in any way whether or not the purpose is to reveal any
                information, including but not limited to personal identification information, other
                than Your own information, as provided for by the Platform;
              </Text>
              <Text paddingLeft={8} marginTop={2}>
                8.2.7 You shall not make any negative, denigrating or defamatory statement(s) or
                comment(s) about Us or the brand name, trade name or domain name used by Us or
                otherwise engage in any conduct or activity that might spoil the image or reputation
                of Zopper.
              </Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration={'underline'}>Audit and Information Sharing:</Text>
            <Text paddingLeft={5} marginTop={2}>
              9.1 Zopper at all times reserves its right, but not an obligation, to audit the
              Content, User Content, Features available on Platform or any other material or
              information posted/uploaded by the User. If at any time, in sole discretion of Zopper,
              Zopper determines that any Content/User Content or any other material or Feature of
              the Platform is in contravention of any law for the time being in force or spirit of
              the terms and conditions of this Agreement or violates the privacy of any person, then
              Zopper may either remove or edit or block such material without any notice.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              9.2 Further to this it is informed that Zopper may share the information pertaining to
              such instance (with or without demand) with government agency for the purpose of
              verification of identity of User, or for prevention, detection, investigation,
              prosecution and punishment. Therefore it is advised to You that You should not involve
              in any such activities or use which violate any applicable law, rules, regulation or
              breach the terms and conditions of this Agreement.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              9.3 &quot;Contents&quot; or &quot;Content&quot; shall mean any and all information,
              data, text, photographs, graphics, video, messages, materials, forms, documents or
              other materials and information which may be viewed or downloaded on or through this
              Platform.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              9.4 &quot;Feature&quot; or &quot;Features&quot;: A &quot;Feature&quot; may include any
              interactive, value addition service or other additional feature. which are introduced
              or available on the Platform.
            </Text>
          </ListItem>
          <ListItem>
            <Text as='span'>
              <Text textDecoration={'underline'} as='span'>
                Third Party Service Providers:
              </Text>
              <Text as='span'>
                {' '}
                Zopper uses third-party service providers to assist Zopper in improving our Services
                and
              </Text>
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              Platform and to monitor our Users’ interests and activities. You hereby authorise
              Zopper and/or third party service providers engaged by Zopper to collect, use, store,
              analyse, reproduce, publish, and adapt (either on its own or through third party
              service provider) the information in relation to Your use of the Platform for the
              purpose of data analysis and for improving Your experience on the Platform. You
              acknowledge that this is solely undertaken by Zopper to improve Your experience in
              relation to the use of the Platform and the provision of such Services shall be
              subject to such additional terms and conditions of Zopper and/or third party service
              providers.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Payment related information:</Text>
            <Text paddingLeft={5} marginTop={2}>
              11.1 Access to Platform is free and Users (Registered or Guest) can browse the
              information/products and avail the Services free of charge.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              11.2 Please note that Zopper is only assisting in collection of premium amount on
              behalf of the insurer, whose insurance product You have chosen to buy. The acceptance
              of the insurance premium and final issuance of the policy is subject to underwriting
              norms and discretion of respective insurance company, whose policy You have chosen to
              buy.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>License and Platform Access</Text>
            <Text paddingLeft={5} marginTop={2}>
              12.1 Subject to the compliance with all applicable laws, rules and terms and
              conditions of this Agreement and/or Additional Terms, Zopper grants the User a
              limited, non-exclusive, non-sub-licensable, non-transferable, revocable and limited
              permission to access and use the Platform and avail the Services provided through or
              at the Platform, subject to the following conditions:
              <Text paddingLeft={5} marginTop={2}>
                12.1.1 The license shall not include: (i) any resale or commercial use of this
                Platform or its Contents; or (ii) any derivative use of this Platform or its
                Contents;
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                12.1.2 You, your partners, your employees, your agents or any other person
                associated with you personally or professionally, may not, with or without
                compensation, prepare any derivative works from, or sell, lease, license, e-mail,
                reproduce, duplicate, copy, resell, republish, transmit or distribute or otherwise
                exploit for any commercial purpose via any media, now known or unknown, any Contents
                from this Platform to any other entity or individual.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                12.1.3 No Contents or information on the Platform may be reproduced in any form or
                incorporated into any internet web-site or any other information retrieval system,
                either electronic or mechanical. It is a condition of Your use of this Platform that
                You do not restrict or inhibit any other user from using this Platform.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                12.1.4 You shall not frame or utilize framing techniques to enclose any trademark,
                logo, or other proprietary information (including images, text, page layout, or
                form) of Zopper without the express written consent of Zopper.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                12.1.5 Any software that is available on the Platform is the property of Zopper or
                its vendors. You may not use, download or install any software available at the
                Platform, unless otherwise expressly permitted by the Agreement or by the express
                written permission of Zopper.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                12.1.6Any unauthorized use terminates the permission and the license granted by
                Zopper under this Agreement to you.
              </Text>
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              12.2 Modification: All information available on Platform is made available to You on
              best efforts basis, however, Zopper does not guarantee nor assume any responsibility
              for the correctness of such information/data. Zopper reserves the right to modify,
              suspend/cancel, or discontinue any or all sections, or service at any time without
              notice. In case the information (including without limitation pricing, description and
              any other information etc.) is found to be not up to date or incorrect, Zopper
              reserves the right to make modifications and alterations in the information contained
              on the Platform without notice. You agree that Zopper shall not be liable to you or to
              any third party for any modification, suspension or discontinuance of the Platform.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Intellectual Property Right:</Text>
            <Text paddingLeft={5} marginTop={2}>
              13.1 The Platform itself and all Content and other works on the Platform are the
              copyrighted works of Zopper or its licensors. Any alteration of the Platform or
              alteration of the material or use of the material contained in the Platform for any
              commercial purpose is a violation of the copyright of Zopper.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.2 Platform may contain also contain certain trade-marks, logos or service marks
              (“Marks”). These Marks are the proprietary to the Zopper or third parties. You are not
              permitted to use the Marks, in any manner, without first obtaining the written
              permission of the Zopper or third party that own and control the Marks.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.3 YOU ACKNOWLEDGE AND AGREE THAT THROUGH YOUR USE OF PLATFORM OR BY AVAILING ANY
              PRODUCT OR SERVICES THROUGH OR AT PLATFORM, NO RIGHT (EMPRESS OR IMPLIED) IS GRANTED
              TO YOU IN RESPECT OF SUCH CONTENT. ZOPPER RESERVES THE RIGHT TO CHANGE OR MODIFY THE
              CONTENT FROM TIME TO TIME AT ITS SOLE DISCRETION.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.4 You further acknowledge and agree that You shall not shall not copy, reproduce,
              republish, upload, post, transmit or distribute, the Content available on the
              Platform, in any way without obtaining the prior permission from Zopper or its
              licensors. All responsibility and liability for any damages caused by downloading of
              any data is disclaimed.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.5 It is clarified that any use of the Platform, Content, Marks or other
              intellectual property rights of Zopper, in contravention of the terms of this
              Agreement or any applicable law shall constitute the infringement of such intellectual
              property right of Zopper or their third party, upon which Zopper or third party may
              initiate the appropriate legal proceedings against the User.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.6 Unless otherwise indicated or anything contained to the contrary or any
              proprietary material owned by a third party and so expressly mentioned, Zopper owns
              all intellectual property rights to and into the trademark
              &quot;zopperinsurance&quot;, and the Platform, including, without limitation, any and
              all rights, title and interest in and to copyright, related rights, patents, utility
              models, designs, know-how, trade secrets and inventions (patent pending), goodwill,
              source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              13.7 ALL RIGHTS NOT EXPRESSLY GRANTED HEREUNDER TO THE USER, ARE RESERVED EXCLUSIVELY
              TO ZOPPER.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Indemnification:</Text>
            <Text paddingLeft={5} marginTop={2}>
              14.1 You agree to indemnify, defend and hold harmless Zopper and its respective
              directors, officers, employees and contractors (herein after individually and
              collectively referred to as &quot;Indemnified Parties&quot;) from and against any and
              all losses, liabilities, claims, suits, proceedings, penalties, interests, damages,
              demands, costs and expenses (including legal and other statutory fees and
              disbursements in connection therewith and interest chargeable thereon) asserted
              against or incurred by the Indemnified Parties that arise out of, result from, or in
              connection with (i) Your breach of the Agreement(s); or (ii) any claims made by any
              third party due to, or arising out of, or in connection with, Your use of the
              Platform; or (iii) any claim that any User Content, information or materials provided
              by You caused damage to a third party; or (iv) Your violation of any rights of
              another, including any intellectual property rights.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              14.2 Zopper may notify You of any claims which You shall be liable to indemnify Zopper
              against. You will then be required to consult with Zopper regarding the course of
              action to be undertaken in defending such a claim. Further, You shall not compromise
              or settle any claim or admit any liability or wrongdoing on the part of Zopper without
              the express prior written consent of Zopper which can be withheld or denied or
              conditioned by Zopper in its sole discretion.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Disclaimer of Warranty and Liability:</Text>
            <Text paddingLeft={5} marginTop={2}>
              15.1 ALL CONTENT, OR INFORMATION PERTAINING PLATFORM OR ANY PRODUCT / SERVICES ARE
              PROVIDED TO YOU ON “AS IS” BASIS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED,
              STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              15.2 WHEN YOU ACCESS THE PLATFORM OR AVAIL ANY PRODUCT OR SERVICES AT OR
              THROUGHPLATFORM, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE MAY BE DELAYS,
              OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER PROBLEMS WITH THE INFORMATION,
              AND SERVICES PUBLISHED ON OR PROMOTED THROUGH THIS PLATFORM. WITHOUT LIMITING THE
              FOREGOING, ZOPPER MAKES NO WARRANTY THAT:
              <Text paddingLeft={5} marginTop={2}>
                15.2.1 THE PLATFORM OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE
                PLATFORM OR THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE;
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                15.2.2 THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM OR SERVICES
                WILL BE EFFECTIVE, ACCURATE OR RELIABLE
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                15.2.3 THE QUALITY OF THE PLATFORM OR SERVICES WILL MEET YOUR EXPECTATIONS; OR
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                15.2.4 ANY ERRORS OR DEFECTS IN THE PLATFORM OR SERVICES WILL BE CORRECTED. NO
                ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ZOPPER OR
                THROUGH THE PLATFORM / CONTENT OR FROM USE OF THE SERVICES SHALL CREATE ANY WARRANTY
                NOT EXPRESSLY STATED IN THESE TERMS OF USE.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                15.2.5 PLATFORM WILL BE OR CONTINUOUSLY AVAILABLE, OR THAT THIS PLATFORM WILL BE
                FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS AND OTHER CODES THAT CONTAIN
                CONTAMINATING OR DESTRUCTIVE PROPERTIES.
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                15.2.6 TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO THE APPLICABLE LAW, ZOPPER
                DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED RELATED TO PLATFORM, CONTENT, USER
                CONTENT, SERVICES, ARISING UNDER INTELLECTUAL PROPERTY RIGHT, LIBEL, PRIVACY
                PUBLICITY, OBSCENITY OR OTHER LAWS. ZOPPER ALSO DISCLAIMS ALL LIABILITY WITH RESPECT
                TO THE MISUSE, LOSS, MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.
              </Text>
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              15.3 NOTWITHSTANDING ANYTHING TO CONTRARY IN THE AGREEMENT(S), IN NO EVENT SHALL
              ZOPPER AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, BE LIABLE TO YOU FOR ANY
              SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES
              WHATSOEVER, INCLUDING THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR
              NOT FORESEEABLE OR WHETHER OR NOT ZOPPER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR
              WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT OF OR IN
              CONNECTION WITH YOUR USE OF OR ACCESS TO THE PLATFORM, SERVICES, CONTENT, USER
              CONTENT.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              15.4 YOU FURTHER ACKNOWLEDGE AND AGREE THAT IF YOU PURCHASE/DOWNLOAD THE PLATFORM FROM
              OTHER THIRD PARTY SERVICE PROVIDERS (e.g. GOOGLE PLAY STORE, APPLE ETC.), THEN NEITHER
              ZOPPER NOR ANY THIRD PARTY SERVICE PROVIDER SHALL BE HELD LIABLE FOR ERROR OR FAILURE
              ON THE PART OF THE PLATFORM TO FUNCTION PROPERLY. YOU SHALL FOLLOW THE
              RULES/GUIDELINES BEFORE DOWNLOADING/INSTALLING THE PLATFORM AS RECOMMENDED BY THIRD
              PARTY SERVICE PROVIDERS.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              15.5 ZOPPER ENTIRE AND AGGREGATE LIABILITY TO YOU UNDER AND IN RELATION TO THESE TERMS
              OF USE OR OTHERWISE SHALL NOT EXCEED THE GREATER OF INDIAN RUPEES ONE HUNDRED (INR
              100) OR THE AMOUNT OF FEES, IF ANY, PAID BY YOU TO ZOPPER UNDER THE RELEVANT ORDER TO
              WHICH THE CAUSE OF ACTION FOR THE LIABILITY RELATES.
            </Text>
          </ListItem>
          <ListItem>
            <Text as='span'>
              <Text textDecoration={'underline'} as='span'>
                Governing Law and Jurisdiction:
              </Text>
              <Text as='span'>
                {' '}
                This Agreement or the documents incorporated herein by reference shall be governed
                and construed in accordance with the laws of India. All disputes arising under this
                Agreement between You and Zopper shall be subject to the exclusive jurisdiction of
                courts at New Delhi, India.
              </Text>
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Force Majeure</Text>
            <Text paddingLeft={5} marginTop={2}>
              17.1 Zopper shall not be liable for any failure and/or delay on its part in performing
              any of its obligation under this Agreement and/or for any loss, damage, costs, charges
              and expenses incurred and/or suffered by the User by reason there of if such failure
              and/or delay shall be result of or arising out of Force Majeure Event set out herein.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              17.2 &quot;Force Majeure Event&quot; means any event due to any cause beyond the
              reasonable control of Zopper, including, without limitation, unavailability of any
              communication system, sabotage, fire, flood, earthquake, explosion, acts of God, civil
              commotion, pandemic, strikes, lockout, and/or industrial action of any kind, breakdown
              of transportation facilities, riots, insurrection, hostilities whether war be declared
              or not, acts of government, governmental orders or restrictions, breakdown and/or
              hacking of the Platform and/or Contents provided and/or services under the Platform,
              such that it is impossible to perform the obligations under the Agreement, or any
              other cause or circumstances beyond the control of Zopper hereto which prevents timely
              fulfilment of obligation of Zopper.
            </Text>
          </ListItem>
          <ListItem>
            <Text textDecoration='underline'>Miscellaneous:</Text>
            <Text paddingLeft={5} marginTop={2}>
              18.1 Notice: Except as explicitly stated otherwise, any notices shall be given by
              postal mail/email
              <Text paddingLeft={5} marginTop={2}>
                18.1.1 On behalf of Zopper to:
                <Text paddingLeft={5} marginTop={2}>
                  Zopper Insurance Brokers Private Limited Attn: Grievance Officer
                </Text>
                <Text paddingLeft={5} marginTop={2}>
                  Address: Corporate Office – 7th Floor, Plot no 1, BGR Tower, Sector 16 A, NOIDA
                  U.P. 201301
                </Text>
                <Text paddingLeft={5} marginTop={2}>
                  Email:{' '}
                  <Link as='a' href='mailto:care@zopperinsurance.com' color='blue.500'>
                    care@zopperinsurance.com
                  </Link>
                </Text>
              </Text>
              <Text paddingLeft={5} marginTop={2}>
                18.1.2 On behalf of User to
                <Text paddingLeft={5} marginTop={2}>
                  All notices with respect to these Terms of Use from Zopper will be served to You:
                </Text>
                <Text paddingLeft={5} marginTop={2}>
                  By email (as provided by You at the time of Registration or communicating with
                  Zopper) or by general notification on the Platform.
                </Text>
              </Text>
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.2 Assignment: You cannot assign or otherwise transfer the Agreements, or any rights
              granted hereunder or any obligations, to any third party and any such assignment or
              transfer or purported assignment or transfer shall be void ab initio. Zopper’s rights
              and/or obligations under the Agreement are freely assignable or otherwise transferable
              by Zopper to any third parties without the requirement of seeking Your prior consent.
              Zopper shall have right to transfer Your Account and Account Information to a third
              party who purchases Zopper’s business as conducted under the Platform.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.3 Severability: If any clause of this Agreement shall be deemed invalid, void or
              for any reason unenforceable, such clause shall be deemed severable and shall not
              affect the validity and enforceability of the remaining clauses of the Agreement.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.4 Waiver: Any failure or delay by a party to enforce or exercise any provision of
              the Agreement, or any related right, shall not constitute a waiver by such party of
              that provision or right. The exercise of one or more of a party’s rights hereunder
              shall not be a waiver of, or preclude the exercise of, any rights or remedies
              available to such party under these Terms of Use or in law or at equity. Any waiver by
              a party shall only be made in writing and executed by a duly authorized officer of
              such party.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.5 Independent Contractor: You and Zopper are independent contractors, and nothing
              in these Terms of Use will be construed to create a partnership, joint venture,
              association of persons, agency (disclosed or undisclosed), franchise, sales
              representative, or employment relationship between You and Zopper.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.6 Contacting You: By accessing Our Platform and/or availing any service or product
              at or through Our Platform, You give Your consent to Us and both our associate
              partners (including Dealers, financial institutions etc.) to communicate with You
              through phone calls/SMS/email communication etc. regardless whether your number is
              registered in the National Do Not Call Registry (NDNC) or{' '}
              <Link href='http://www.nccptrai.gov.in/' color='blue.500' isExternal>
                www.nccptrai.gov.in
              </Link>
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.7 Survival: Rights and obligations under this Agreement, which by their nature
              should survive or are expressly so stated herein, shall remain in full force and
              effect notwithstanding any expiry or termination of this Agreement.
            </Text>
            <Text paddingLeft={5} marginTop={2}>
              18.8 Grievance Officer: In compliance with Information Technology Act, 2000 and the
              rules made thereunder, the Grievance Officer of Zopper for the purpose of this
              Agreement shall be the following:
            </Text>
            <UnorderedList spacing={2} marginTop={2} paddingLeft={10}>
              <ListItem>Name Rajeev Kumar Rout</ListItem>
              <ListItem>Contact Number: 7669248641</ListItem>
              <ListItem>
                <Text marginTop={2}>
                  Email:{' '}
                  <Link as='a' href='mailto:care@zopperinsurance.com' color='blue.500'>
                    care@zopperinsurance.com
                  </Link>
                </Text>
              </ListItem>
              <ListItem>Working Days: Monday to Friday</ListItem>
              <ListItem>Working Hours: 10:30 am to 6:30 pm</ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </Flex>
      <Flex marginTop={'auto'}>
        <Footer />
      </Flex>
    </Flex>
  )
}
