import { Divider, Flex, Image, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import Logo from 'Assets/ZIBPL/footer_logo3x.png'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const navigate = useNavigate()
  return (
    <Flex bgColor='#1C1F3E' paddingTop='4'>
      <Flex
        direction='column'
        alignItems='center'
        color='white'
        paddingX='12'
        paddingY='4'
        justifyContent='space-evenly'
        width='100%'
      >
        <div className='flex justify-around xl:flex-row lg:flex-row flex-col text-xs w-[100%]'>
          <Flex direction='column' className='xs:my-2'>
            <Link href='https://www.zopper.com/' isExternal>
              <Image src={Logo} height='auto' width='150px' cursor={'pointer'} marginBottom='2' />
            </Link>
            <Text className='xs:my-4'>
              Zopper Insurance Brokers Private Limited
              <br />
              (CIN no U66000DL2020PTC373851)
            </Text>
            <Text>Principal Officer : Bhushan Harendra Sheth</Text>
          </Flex>
          <Flex direction='column' className='xs:my-2'>
            <Text fontWeight='semibold' marginBottom='4'>
              QUICK LINK
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/privacy-policy')
              }}
            >
              Privacy Policy
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/grievance-policy')
              }}
            >
              Grievance Policy
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/fraud-detection')
              }}
            >
              Fraud Detection
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/shipping-policy')
              }}
            >
              Shipping Policy
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/terms-of-use')
              }}
            >
              Terms of Use
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/cancellation-and-refund')
              }}
            >
              Cancellation And Refund
            </Text>
            <Text
              cursor='pointer'
              marginBottom={2}
              onClick={() => {
                window.scrollTo(0, 0)
                navigate('/e-insurance-account')
              }}
            >
              E-Insurance Account
            </Text>
          </Flex>
          <Flex direction='column' className='xs:my-2'>
            <Text fontWeight='semibold' marginBottom='4'>
              ADDRESS
            </Text>
            <Text marginBottom='2' fontWeight={'medium'}>
              Registered Office Address
            </Text>
            <Text marginBottom={4}>
              503, 5th Floor, Bhikaji Cama Bhawan
              <br /> Bhikaji Cama Place, New Delhi-110066
            </Text>
            <Text fontWeight='semibold' marginBottom='2'>
              Corporate Office Address
            </Text>
            <Text>
              7th Floor. Plot no 1. BGR Tower.
              <br /> Sector 16 A, NOIDA U.P. 201301
            </Text>
          </Flex>
          {/* <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  CORPORATE OFFICE ADDRESS
                </Text>
                <Text>
                  7th Floor, BGR Tower, Plot No.1,
                  <br /> Sector 16 A, Film City, Noida- 201301
                </Text>
              </Flex> */}
          <Flex direction='column' className='xs:my-2'>
            <Text fontWeight='semibold' marginBottom='4'>
              CONTACT US
            </Text>
            <Text marginBottom='2'>
              <EmailIcon /> care@zopperinsurance.com
            </Text>
            <Text marginBottom='2'>
              <PhoneIcon /> +91 7669248641
            </Text>
          </Flex>
        </div>
        <Divider orientation='horizontal' bgColor='white' marginY='4' width='93%' />
        <Text fontSize={'12px'} fontWeight={'normal'}>
          IRDAI License no 790. Direct Broker (Life & General) valid till 23rd December 2024
        </Text>
        <Text
          marginX={['0px', '0px', '110px']}
          textAlign='left'
          fontSize='12px'
          lineHeight='16px'
          marginBottom='8'
          marginTop='2'
        >
          Disclaimer: *Standard T&amp;C Apply. All savings/discounts are offered by insurance
          companies as approved by the IRDAI for the product under applicable file &amp; use
          guidelines. Savings in motor insurance are asapproved by the IRDAI
          {/* <Text
                as='span'
                color='#E95F2B'
                cursor='pointer'
                _hover={{ textDecoration: 'underline' }}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate('/irdai')
                }}
              >
                IRDAI
              </Text> */}{' '}
          as per the File &amp; Use Guidelines. For more details on risk factors, terms and
          conditions, please read the sales brochure of respective insurers carefully before
          concluding a sale. Tax benefits are subject to changes in applicable tax laws. You
          authorise Zopper Insurance Brokers Pt Ltd (Zopperinsurance) to contact you through call,
          SMS, email, WhatsApp or any other mode in the future. You hereby override your NDNC
          registration.
        </Text>
      </Flex>
    </Flex>
  )
}
