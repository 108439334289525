import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'
import { Flex, Text } from '@chakra-ui/react'

export default function ShippingPolicy() {
  return (
    <Flex minH={'100vh'} flexDir={'column'}>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Shipping Policy
        </Text>

        <Text>
          For any insurance product purchased through Zopper Insurance Brokers Private Limited
          (ZIBPL) website (www.zopperinsurance.com), a soft copy of the policy will be emailed to
          the email id given at the time of buying the product provided the same is permissible as
          per the norms of respective Insurance Company. The soft copy will be emailed to you by
          ZIBPL or respective Insurance Company or both.
        </Text>
        <Text marginTop={2}>
          The Insurance Company will endeavour to deliver a hard copy of the policy documents,
          within 2-3 weeks of buying the insurance policy or such time as permissible under
          applicable laws or regulations.
        </Text>
      </Flex>
      <Flex marginTop={'auto'}>
        <Footer />
      </Flex>
    </Flex>
  )
}
