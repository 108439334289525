import { AboutUs, Home, Login } from './Pages'
import { QuotationPage } from 'Pages/Health'
import { Proposal, VehicleDetails, VehicleInsuranceDetails } from 'Pages/Motor'
import { Route, Routes } from 'react-router-dom'
import { PaymentModal } from 'Core'
import { Quotation } from 'Pages/Motor/Quotation'
import { IRDAI } from 'Pages/Common/IRDAI'
import PrivacyPolicy from 'Pages/Common/PrivacyPolicy'
import GrievancePolicy from 'Pages/Common/GrievancePolicy'
import FraudDetection from 'Pages/Common/FraudDetection'
import ShippingPolicy from 'Pages/Common/ShippingPolicy'
import TermsUse from 'Pages/Common/TermsUse'
import CancellationRefund from 'Pages/Common/CancellationRefund'
import EInsuranceAccount from 'Pages/Common/EInsuranceAccount'
import { Home2 } from 'Pages/Common/Home/Home'

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Home2 />} />
      <Route path='/irdai' element={<IRDAI />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/grievance-policy' element={<GrievancePolicy />} />
      <Route path='/fraud-detection' element={<FraudDetection />} />
      <Route path='/shipping-policy' element={<ShippingPolicy />} />
      <Route path='/terms-of-use' element={<TermsUse />} />
      <Route path='/cancellation-and-refund' element={<CancellationRefund />} />
      <Route path='/e-insurance-account' element={<EInsuranceAccount />} />
      {/* <Route path='/login' element={<Login />} /> */}
      {/* <Route path='/quote' element={<QuoteItem />} /> */}
      {/* <Route path='/aboutus' element={<AboutUs />} />

      <Route path='/health/quotes' element={<QuotationPage />} />
      <Route path='/vehicle-details' element={<VehicleDetails />} />
      <Route path='/motor/car/details' element={<VehicleInsuranceDetails />} />
      <Route path='/motor/proposal' element={<Proposal />} />
      <Route path='/motor/quotation' element={<Quotation />} /> */}
    </Routes>
  )
}
