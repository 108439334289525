import { createStandaloneToast } from '@chakra-ui/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosRequestHeaders } from 'axios'
import { API_ENDPOINTS } from 'Constants'
// import { resetUser, updateUserDetails } from 'features/Authentication'
import { IUserCredentials, IUserMobileCredentials } from 'Models/RequestModels'
import { IAuthenticatedUser } from 'Models/ResponseModels'
import { useParams } from 'react-router-dom'
import { foundationUrlQuery } from 'Services/baseQuery'
import { ToastService } from 'Services/toast.service'

const prepareHeaders = () => {
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  const { token } = useParams()
  if (token) {
    headers.set('Authorization', `Token ${token}`)
  }
  return headers as any
}
export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: foundationUrlQuery,
  tagTypes: ['Login', 'Logout'],

  endpoints: (builder) => ({
    loginWithCredentials: builder.mutation<
      IAuthenticatedUser,
      IUserCredentials | IUserMobileCredentials
    >({
      query: (formData: IUserCredentials | IUserMobileCredentials) => ({
        url: API_ENDPOINTS.LOGIN,
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled
          //   dispatch(updateUserDetails(user))
          ToastService.showSuccessTaost({
            title: 'Success',
            message: 'Login Successful',
          })
        } catch (err: any) {
          // TODO: add failure toast here
          ToastService.showErrorToast({ title: 'Error', message: err['error']['message'] })
        }
      },
    }),
    // loginWithMobileCredentials: builder.mutation<IAuthenticatedUser, IUserMobileCredentials>({
    //   query: (formData: IUserMobileCredentials) => ({
    //     url: API_ENDPOINTS.LOGIN,
    //     method: 'POST',
    //     body: formData,
    //     headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
    //   }),
    //   async onQueryStarted(_, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data: user } = await queryFulfilled
    //       dispatch(updateUserDetails(user))
    //       console.log(user.businessname)
    //       ToastService.showSuccessTaost({
    //         title: 'Success',
    //         message: 'Login Successful',
    //       })
    //     } catch (err: any) {
    //       // TODO: add failure toast here
    //       console.log('Errrr...', err)
    //       ToastService.showErrorToast({ title: 'Error', message: err['error']['message'] })
    //     }
    //   },
    // }),
    loginWithSso: builder.query<any, string>({
      query: (token) => ({
        url: API_ENDPOINTS.LOGIN,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Source: 'Web',
          Lang: 'EN',
          Authorization: `Token ${token}`,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled
          //   dispatch(updateUserDetails(user))
          ToastService.showSuccessTaost({
            title: 'Success',
            message: 'Login Successful',
          })
        } catch (err: any) {
          // TODO: add failure toast here
          ToastService.showErrorToast({ title: 'Unsuccessful', message: 'Invalid Token' })
        }
      },
    }),
    logout: builder.mutation<void, null>({
      query: () => ({
        url: API_ENDPOINTS.LOGOUT,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          //   dispatch(resetUser())
          ToastService.showSuccessTaost({
            title: 'Success',
            message: 'Logout Successful',
          })
        } catch (e: any) {
          ToastService.showErrorToast({
            title: e['error']['data']['detail'] ?? 'Something went wrong',
          })
        }
        //  finally {
        //   dispatch(resetUser())
        // }
      },
    }),
    sendOtp: builder.mutation<any, IUserMobileCredentials>({
      query: (formData: IUserMobileCredentials) => ({
        url: API_ENDPOINTS.SEND_OTP,
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
        // Authorization: `Token ${authtoken}`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (err: any) {
          // TODO: add failure toast here
          ToastService.showErrorToast({ title: 'Error', message: err['error']['message'] })
        }
      },
    }),
  }),
})

export const {
  useLoginWithCredentialsMutation,
  useLogoutMutation,
  useLazyLoginWithSsoQuery,
  useSendOtpMutation,
} = authenticationApi
