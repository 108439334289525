export const stylesContants = {
  FORM_CONTROL: {
    marginBottom: '0rem',
  },
  FORM_LABEL: {
    fontSize: '14px',
    fontWeight: 'medium',
  },
  LINK_STYLE: {
    fontSize: '18px',
    color: 'blue.500',
  },
  PAGE_HEADERS: {
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  HIGHLIGHTED_TEXT: {
    fontSize: '3xl',
    fontWeight: 'extrabold',
    color: 'primary.500',
  },
  TEXT_BASE: {
    margin: '0',
  },
  TEXT_LABEL: {
    color: 'gray.500',
    fontSize: 'xs',
  },
}

export const tabPanelStyles = {
  bgColor: 'white',
  borderRadius: 'lg',
  maxWidth: '93vw',
  boxShadow: '0px 4px 10px #0000001a',
  paddingY: '1rem',
}
