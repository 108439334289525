import {
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
  Text,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  HStack,
  PinInputField,
  PinInput,
  Center,
  Select,
  Link,
} from '@chakra-ui/react'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import icon from 'Assets/private-icon.svg'
import { useNavigate } from 'react-router-dom'
import { Header } from 'Components'
import Login from 'Assets/ZIBPL/Login Illustration copy.jpg'
import Motor from 'Assets/ZIBPL/Motor/Motor.png'
import Health from 'Assets/ZIBPL/Health/Health.png'
import Life from 'Assets/ZIBPL/Life/Life.png'
import Podium from 'Assets/ZIBPL/podium/podium@2x.png'
import Trophy from 'Assets/ZIBPL/Repeat Grid 1/Repeat Grid 1@2x.png'
import Quality from 'Assets/ZIBPL/quality/quality@2x.png'
import Service from 'Assets/ZIBPL/24-7/24-7@2x.png'
import Dots from 'Assets/ZIBPL/dots.jpg'
import Footer from 'Assets/ZIBPL/footer_logo3x.png'
import { stylesContants } from 'Constants/styleConstants'
import { useState } from 'react'
import {
  useLoginWithCredentialsMutation,
  useSendOtpMutation,
} from 'Services/API/authentication.api'
import { SERVER } from 'Constants'
import { ToastService } from 'Services/toast.service'
import { onlyAllowTypingNumbers, openLinkInSpecifiedTab } from 'Services/general'

const { FORM_CONTROL, FORM_LABEL } = stylesContants

const alphabetAndNumberWithOnly_ = /^[a-zA-Z_\d\s]+$/

const formValidation = (fieldName: string, fieldValue: any) => {
  switch (fieldName) {
    case 'username':
      if (fieldValue < 1) {
        return true
      }
      break
    case 'password':
      if (fieldValue < 1) {
        return true
      }
      break
    case 'mobile':
      if (fieldValue < 10) {
        return true
      }
      break
    case 'title':
      if (fieldValue === '') {
        return false
      } else if (!alphabetAndNumberWithOnly_.test(fieldValue)) {
        return 'Special Characters Not Allowed'
      } else if (fieldValue < 1) {
        return true
      } else {
        return false
      }
      break
    case 'campaign_subject':
      if (fieldValue === '') {
        return false
      } else if (!alphabetAndNumberWithOnly_.test(fieldValue)) {
        return 'Special Characters Not Allowed'
      } else if (fieldValue < 1) {
        return true
      } else {
        return false
      }
      break
    case 'text':
      if (!/^[a-zA-Z_]+$/.test(fieldValue)) {
        return 'Special Characters Not Allowed'
      } else if (fieldValue < 1) {
        return true
      }
      break
    case 'image':
      return false
      break
    case 'video':
      return true
      break
    case 'document':
      return true
      break
    default:
      return false
  }
}

const Home2 = () => {
  const navigate = useNavigate()
  const [loginWithCredentials, { isLoading, isError, isSuccess }] =
    useLoginWithCredentialsMutation()
  const [sendOtp, { isLoading: isOtpLoading }] = useSendOtpMutation()
  const [loginFormData, setLoginFormData] = useState({
    username: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    username: false,
    password: false,
  })

  const [loginFormData2, setLoginFormData2] = useState({
    mobile: '',
    otp: '',
  })
  const [otp, setOtp] = useState('')

  const [errors2, setErrors2] = useState({
    mobile: false,
    otp: false,
  })

  const [otpSent, setOtpSent] = useState(false)
  const [business, setBusiness] = useState('zibpl')

  const handleInput = (e: any) => {
    if (e.target.value[0] === ' ') {
      return
    }
    e.preventDefault()
    const formFieldName = e.target.name
    const formFieldValue = e.target.value
    const getValidation = formValidation(formFieldName, formFieldValue)
    if (!getValidation) {
      setErrors((prev) => ({
        ...prev,
        [formFieldName]: getValidation,
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        [formFieldName]: false,
      }))
    }
    setLoginFormData((prev: any) => ({
      ...prev,
      [formFieldName]: formFieldValue,
    }))
  }

  const handleInput2 = (e: any) => {
    if (e.target.value[0] === ' ') {
      return
    }
    e.preventDefault()
    const formFieldName = e.target.name
    const formFieldValue = e.target.value
    const getValidation = formValidation(formFieldName, formFieldValue)
    if (!getValidation) {
      setErrors2((prev) => ({
        ...prev,
        [formFieldName]: getValidation,
      }))
    } else {
      setErrors2((prev) => ({
        ...prev,
        [formFieldName]: false,
      }))
    }
    setLoginFormData2((prev: any) => ({
      ...prev,
      [formFieldName]: formFieldValue,
    }))
  }
  return (
    <>
      <Header />
      <Flex direction='column'>
        <Flex direction='row' alignItems='center' justifyContent='space-evenly'>
          <Flex direction='column' width='65%'>
            <div className='py-2 px-6'>
              <div className='lg:basis-[55%]'>
                <Flex marginBottom='4' fontSize='40px' fontWeight='bold'>
                  <Text marginRight='2'>About</Text>
                  <Text color='#E95F2B'>Zopper</Text>
                </Flex>
                <Text color='#574747' textAlign='justify'>
                  Zopper is one of India’s leading insurance platform. We are a Direct Broker
                  licensed by the Insurance Regulatory and Development Authority (IRDA) for Life and
                  Non-Life Insurance in India. We simplify the complexities of insurance, analyze
                  the risks, help the clients in providing the most appropriate insurance solutions
                  and also extend assistance in prompt settlement of claims through our dedicated
                  team of highly result oriented professionals.
                </Text>
              </div>
            </div>
            <Flex direction='column' bgColor='#F8F8F8'>
              <Text fontWeight='semibold' fontSize='30px' marginY='5' paddingX='6'>
                Services we offer
              </Text>
              <div className='px-4 py-8 flex xl:flex-row lg:flex-row flex-col'>
                <Box
                  bgColor='white'
                  borderRadius='15px'
                  flexBasis='30%'
                  paddingY='4'
                  paddingX='2'
                  marginX='4'
                >
                  <Flex direction='column' alignItems='center'>
                    <Image src={Motor} width='auto' height='40px' />
                    <Text fontSize='20px' fontWeight='semibold' marginY='4'>
                      Motor Insurance
                    </Text>
                    <Text textAlign='center' color='#574747' fontSize='sm'>
                      Motor insurance is an insurance plan focused on protection and coverage for
                      Private & Commercial Vehicles. Motor insurance covers for the loss or damage
                      caused to the automobile or its parts due to accidental damages.
                    </Text>
                  </Flex>
                </Box>
                <div className='bg-white rounded-[15px] basis-[30%] py-4 px-2 lg:my-0 xl:my-0 m-4'>
                  <Flex direction='column' alignItems='center'>
                    <Image src={Health} width='auto' height='40px' />
                    <Text fontSize='20px' fontWeight='semibold' marginY='4'>
                      Health Insurance
                    </Text>
                    <Text textAlign='center' color='#574747' fontSize='sm'>
                      A health insurance policy is a financial safeguard that provides financial
                      coverage to the insured during medical emergencies. A health insurance policy
                      ensures that you can avail cashless treatment at a network hospitals.
                    </Text>
                  </Flex>
                </div>
                <Box
                  bgColor='white'
                  borderRadius='15px'
                  flexBasis='30%'
                  paddingY='4'
                  paddingX='2'
                  marginX='4'
                >
                  <Flex direction='column' alignItems='center'>
                    <Image src={Life} width='auto' height='40px' />
                    <Text fontSize='20px' fontWeight='semibold' marginY='4'>
                      Life Insurance
                    </Text>
                    <Text textAlign='center' color='#574747' fontSize='sm'>
                      Insurer pays a Sum Assured to named nominees when the insured dies or at the
                      time of maturity in exchange for the premiums paid by the policyholder during
                      their lifetime.
                    </Text>
                  </Flex>
                </Box>
              </div>
            </Flex>
          </Flex>
          <Box
            border='0.5px solid #F26522'
            borderRadius='15'
            width='30%'
            marginBottom='-6'
            boxShadow='0 25px 50px -12px rgb(0 0 0 / 0.25)'
          >
            <Flex
              // paddingX='5'
              direction='row'
              marginLeft='5'
              fontSize='18px'
              fontWeight='semibold'
              marginTop='-4'
              backgroundColor='white'
              alignItems='center'
              width='42%'
              zIndex={-1}
            >
              <Image src={icon} width='12' height='auto' />
              <Text marginLeft='1'>Login With Us</Text>
            </Flex>
            <Flex direction='column' paddingX='5' paddingY='6'>
              <form
                onSubmit={async (e) => {
                  e.preventDefault()
                  try {
                    const response = await loginWithCredentials({
                      ...loginFormData,
                      business_type: 'Zopper Insurance Broker Private Limited',
                    }).unwrap()
                    if (business === 'zibpl') {
                      if (
                        response.businessrole_name === 'Motor Insurance Service Provider(MISP)' ||
                        response.businessrole_name === 'Designated Person(DP)' ||
                        response.businessrole_name?.toLowerCase() === 'dealership head'
                      ) {
                        window.open(
                          SERVER === 'PROD'
                            ? `https://misp.zopperinsurance.com/ssotoken/${response.authtoken}`
                            : SERVER === 'PRE'
                            ? `https://misp-pre.zopperinsurance.com/ssotoken/${response.authtoken}`
                            : `https://misp-dev.zopperinsurance.com/ssotoken/${response.authtoken}`,
                          '_self',
                        )
                      } else {
                        window.open(
                          SERVER === 'PROD'
                            ? `https://bima.zopperinsurance.com/ssotoken/${response.authtoken}`
                            : SERVER === 'PRE'
                            ? `https://insurance-v4-pre.zopperinsurance.com/ssotoken/${response.authtoken}`
                            : `https://insurance-v4-uat.zopperinsurance.com/ssotoken/${response.authtoken}`,
                          '_self',
                        )
                      }
                    } else if (business === 'oem') {
                      window.open(
                        SERVER === 'PROD'
                          ? `https://oem.zopperinsurance.com/ssotoken/${response.authtoken}`
                          : `https://oem-dev.zopperinsurance.com/ssotoken/${response.authtoken}`,
                        '_self',
                      )
                    } else if (business === 'prime') {
                      window.open(
                        SERVER === 'PROD'
                          ? `https://prime.zopperinsurance.com/ssotoken/${response.authtoken}`
                          : `https://https://prime-onboard-uat.zopper.info/ssotoken/${response.authtoken}`,
                        '_self',
                      )
                    }
                  } catch (e) {
                    console.log(e)
                  }
                  // await loginWithCredentials()
                }}
              >
                <FormControl
                  isInvalid={errors.username}
                  isRequired={true}
                  {...FORM_CONTROL}
                  marginBottom='20px'
                >
                  <FormLabel {...FORM_LABEL}>UserID/Email</FormLabel>
                  <Input
                    required
                    type='text'
                    borderRadius='sm'
                    name='username'
                    value={loginFormData.username}
                    onChange={handleInput}
                    placeholder='Enter User ID'
                    background='#fff'
                  />
                  {errors.username ? (
                    <FormErrorMessage>Email is a required field</FormErrorMessage>
                  ) : (
                    ''
                  )}
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={errors.password}
                  {...FORM_CONTROL}
                  marginTop='20px'
                >
                  <Flex direction='column' height='100%' width='100%' marginBottom='20px'>
                    <FormLabel {...FORM_LABEL}>Password</FormLabel>
                    <Input
                      required
                      type='password'
                      borderRadius='sm'
                      name='password'
                      value={loginFormData.password}
                      onChange={handleInput}
                      placeholder='Enter Password'
                      background='#fff'
                    />
                  </Flex>{' '}
                  {errors.password ? (
                    <FormErrorMessage>Password is a required field</FormErrorMessage>
                  ) : (
                    ''
                  )}
                </FormControl>
                {/* <Flex direction='column' height='100%' width='100%'>
                  <FormLabel {...FORM_LABEL}>Select Business</FormLabel>
                  <Select
                    defaultValue='zibpl'
                    onChange={(e) => {
                      setBusiness(e.target.value)
                    }}
                  >
                    <option value='zibpl'>ZIBPL</option>
                    <option value='prime'>Prime</option>
                    <option value='oem'>OEM</option>
                  </Select>
                </Flex> */}
                <Flex alignItems='center' justifyContent='center' marginTop='2rem'>
                  <Button
                    isLoading={isLoading}
                    isDisabled={
                      loginFormData.username.length === 0 || loginFormData.password.length === 0
                    }
                    type='submit'
                    paddingX='10'
                    bgColor='#1a6092'
                    color='white'
                  >
                    LOGIN
                  </Button>
                </Flex>
              </form>
              <Flex direction='row' alignItems='center' marginY='4'>
                <Box flexBasis='50%' borderBottom='1px solid black' />
                <Text marginX='1' color='#F26522'>
                  Or
                </Text>
                <Box flexBasis='50%' borderBottom='1px solid black' />
              </Flex>
              <form
                onSubmit={async (e) => {
                  e.preventDefault()
                  if (!otpSent) {
                    try {
                      const response = await sendOtp({
                        phone: loginFormData2.mobile,
                        source: 'ZIBPL',
                      }).unwrap()
                      ToastService.showSuccessTaost({
                        title: 'Success',
                        message: `An otp has been sent to ${loginFormData2.mobile}`,
                      })
                      setOtpSent(true)
                    } catch (e) {
                      setOtpSent(false)
                    }
                  } else {
                    try {
                      const response = await loginWithCredentials({
                        username: loginFormData2.mobile,
                        otp: otp,
                        business_type: 'Zopper Insurance Broker Private Limited',
                      }).unwrap()
                      window.open(
                        SERVER === 'PROD'
                          ? `https://bima.zopperinsurance.com/ssotoken/${response.authtoken}`
                          : SERVER === 'PRE'
                          ? `https://insurance-v4-pre.zopperinsurance.com/ssotoken/${response.authtoken}`
                          : `https://insurance-v4-uat.zopperinsurance.com/ssotoken/${response.authtoken}`,
                        '_self',
                      )
                    } catch (e) {
                      loginFormData2.otp = ''
                      setOtp('')
                    }
                  }
                }}
              >
                <FormControl
                  isRequired={true}
                  isInvalid={errors.password}
                  {...FORM_CONTROL}
                  // marginTop='20px'
                >
                  <Flex direction='column' height='100%' width='100%'>
                    <FormLabel {...FORM_LABEL}>Mobile Number</FormLabel>
                    <Flex direction='row' alignItems='center'>
                      <Text
                        borderLeft='2px solid #F4F5F6'
                        borderY='2px solid #F4F5F6'
                        height='100%'
                        padding='1.5'
                        bgColor='#FBFBFB'
                        color='#DCDCDC'
                        fontSize='md'
                        fontWeight='bold'
                      >
                        +91
                      </Text>
                      <Input
                        required
                        type='text'
                        borderRadius='sm'
                        name='mobile'
                        value={loginFormData2.mobile}
                        onChange={handleInput2}
                        placeholder='Enter Mobile No.'
                        background='#fff'
                        maxLength={10}
                        onKeyDown={onlyAllowTypingNumbers}
                      />
                    </Flex>
                  </Flex>
                  {errors2.mobile ? (
                    <FormErrorMessage>Mobile No. is a required field</FormErrorMessage>
                  ) : (
                    ''
                  )}
                </FormControl>
                {otpSent && (
                  <Flex direction='column' marginY='4'>
                    <FormLabel>OTP</FormLabel>
                    {/* <Center> */}
                    <Flex direction='row'>
                      <HStack>
                        <PinInput
                          placeholder=''
                          value={otp}
                          onChange={(e) => {
                            setOtp(e)
                          }}
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                    </Flex>
                    {/* </Center> */}
                  </Flex>
                )}
                <Flex alignItems='center' justifyContent='center' marginTop='2rem'>
                  {!otpSent ? (
                    <Button
                      isLoading={isOtpLoading}
                      isDisabled={loginFormData2.mobile.length !== 10}
                      border='1px solid #1a6092'
                      type='submit'
                      colorScheme='#1a6092'
                      paddingX='2'
                      color='#1a6092'
                      variant='outline'
                    >
                      SEND OTP
                    </Button>
                  ) : (
                    <Button
                      isLoading={isLoading}
                      isDisabled={otp.length !== 6}
                      border='1px solid #1a6092'
                      type='submit'
                      colorScheme='#1a6092'
                      paddingX='2'
                      color='#1a6092'
                      variant='outline'
                    >
                      LOGIN
                    </Button>
                  )}
                </Flex>
                {otpSent && (
                  <Center paddingY='2' cursor='pointer'>
                    <Text
                      decoration='underline'
                      color='#1a6092'
                      cursor='pointer'
                      onClick={() => {
                        setOtpSent(false)
                        setOtp('')
                      }}
                    >
                      Change Phone Number
                    </Text>
                  </Center>
                )}
              </form>
              <Text fontSize='14px' paddingY='2'>
                By continuing, you agree to Zopper Insurance Brokers Private Limited Terms of Use
                and Privacy Policy
              </Text>
            </Flex>
          </Box>
        </Flex>
        <div className='p-8 justify-evenly items-center relative flex xl:flex-row-reverse lg:flex-row-reverse flex-col-reverse'>
          <Grid
            templateColumns='repeat(2, 1fr)'
            gap='10'
            flexBasis='50%'
            paddingX='10'
            paddingY='6'
          >
            <GridItem bgColor='#F8F8F8' borderRadius='15px' paddingY='4' paddingX='4'>
              <Flex
                direction='column'
                alignItems='center'
                justifyContent='space-evenly'
                paddingY='4'
              >
                <Image src={Podium} width='70px' height='70px' marginBottom='6' />
                <Text fontSize='18px' color='#574747' fontWeight='bold' textAlign='center'>
                  We keep customer first
                </Text>
              </Flex>
            </GridItem>
            <GridItem bgColor='#F8F8F8' borderRadius='15px' paddingY='4' paddingX='4'>
              <Flex
                direction='column'
                alignItems='center'
                justifyContent='space-evenly'
                paddingY='4'
              >
                <Image src={Trophy} width='70px' height='70px' marginBottom='6' />
                <Text fontSize='18px' color='#574747' fontWeight='bold' textAlign='center'>
                  Best Policy offerings in industry
                </Text>
              </Flex>
            </GridItem>
            <GridItem bgColor='#F8F8F8' borderRadius='15px' paddingY='4' paddingX='4'>
              <Flex direction='column' alignItems='center' justifyContent='center' paddingY='4'>
                <Image src={Quality} width='70px' height='70px' marginBottom='6' />
                <Text fontSize='18px' color='#574747' fontWeight='bold' textAlign='center'>
                  Approved By IRDAI
                </Text>
              </Flex>
            </GridItem>
            <GridItem bgColor='#F8F8F8' borderRadius='15px' paddingY='4' paddingX='4'>
              <Flex
                direction='column'
                alignItems='center'
                justifyContent='space-evenly'
                paddingY='4'
              >
                <Image src={Service} width='70px' height='70px' marginBottom='6' />
                <Text fontSize='18px' color='#574747' fontWeight='bold' textAlign='center'>
                  24/7 Customer Support
                </Text>
              </Flex>
            </GridItem>
          </Grid>
          <div className='flex flex-col text-[52px]'>
            <Text marginY='4' fontSize='28px'>
              Our{' '}
              <Text as='span' color='#E95F2B'>
                Key-points
              </Text>
            </Text>
            <p className='font-semibold xs:text-4xl lg:text-[38px] lg:leading-[52px]'>
              What makes{' '}
              <Text as='span' color='#E95F2B'>
                Zopper
              </Text>{' '}
              the
              <br className='xs:hidden lg:block md:block' /> best place to buy Insurance
              <br className='xs:hidden lg:block md:block' /> in India?
            </p>
          </div>
          <Image
            src={Dots}
            position='absolute'
            left='0'
            zIndex='-5'
            bottom='-30'
            width='130px'
            height='auto'
          />
          <Image
            src={Dots}
            position='absolute'
            right='0'
            top='-50'
            zIndex='-5'
            width='120px'
            height='auto'
            transform='scaleX(-1)'
          />
        </div>
        <Flex bgColor='#1C1F3E' paddingTop='4'>
          <Flex
            direction='column'
            alignItems='center'
            color='white'
            paddingX='12'
            paddingY='4'
            justifyContent='space-evenly'
            width='100%'
          >
            <div className='flex justify-around xl:flex-row lg:flex-row flex-col text-xs w-[100%]'>
              <Flex direction='column' className='xs:my-2'>
                <Link href='https://www.zopper.com/' isExternal>
                  <Image
                    src={Footer}
                    height='auto'
                    width='150px'
                    cursor={'pointer'}
                    marginBottom='2'
                  />
                </Link>
                <Text className='xs:my-4'>
                  Zopper Insurance Brokers Private Limited
                  <br />
                  (CIN no U66000DL2020PTC373851)
                </Text>
                <Text>Principal Officer : Raman Bawa</Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  QUICK LINK
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/privacy-policy',
                      '_self',
                    )
                  }}
                >
                  Privacy Policy
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/grievance-policy',
                      '_self',
                    )
                  }}
                >
                  Grievance Policy
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/fraud-detection',
                      '_self',
                    )
                  }}
                >
                  Fraud Detection
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/shipping-policy',
                      '_self',
                    )
                  }}
                >
                  Shipping Policy
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab('https://www.zopperinsurance.com/terms-of-use', '_self')
                  }}
                >
                  Terms of Use
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/cancellation-and-refund',
                      '_self',
                    )
                  }}
                >
                  Cancellation And Refund
                </Text>
                <Text
                  cursor='pointer'
                  marginBottom={2}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    openLinkInSpecifiedTab(
                      'https://www.zopperinsurance.com/e-insurance-account',
                      '_self',
                    )
                  }}
                >
                  E-Insurance Account
                </Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  ADDRESS
                </Text>
                <Text marginBottom='2' fontWeight={'medium'}>
                  Registered Office Address
                </Text>
                <Text marginBottom={4}>
                  503, 5th Floor, Bhikaji Cama Bhawan
                  <br /> Bhikaji Cama Place, New Delhi-110066
                </Text>
                <Text fontWeight='semibold' marginBottom='2'>
                  Corporate Office Address
                </Text>
                <Text>
                  7th Floor. Plot no 1. BGR Tower.
                  <br /> Sector 16 A, NOIDA U.P. 201301
                </Text>
              </Flex>
              {/* <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  CORPORATE OFFICE ADDRESS
                </Text>
                <Text>
                  7th Floor, BGR Tower, Plot No.1,
                  <br /> Sector 16 A, Film City, Noida- 201301
                </Text>
              </Flex> */}
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  CONTACT US
                </Text>
                <Text marginBottom='2'>
                  <EmailIcon /> care@zopperinsurance.com
                </Text>
                <Text marginBottom='2'>
                  <PhoneIcon /> +91 7669248641
                </Text>
              </Flex>
            </div>
            <Divider orientation='horizontal' bgColor='white' marginY='4' width='93%' />
            <Text fontSize={'12px'} fontWeight={'normal'}>
              IRDAI License no 790. Direct Broker (Life & General) valid till 23rd December 2024
            </Text>
            <Text
              marginX={['0px', '0px', '110px']}
              textAlign='left'
              fontSize='12px'
              lineHeight='16px'
              marginBottom='8'
              marginTop='2'
            >
              Disclaimer: *Standard T&amp;C Apply. All savings/discounts are offered by insurance
              companies as approved by the IRDAI for the product under applicable file &amp; use
              guidelines. Savings in motor insurance are asapproved by the IRDAI
              {/* <Text
                as='span'
                color='#E95F2B'
                cursor='pointer'
                _hover={{ textDecoration: 'underline' }}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate('/irdai')
                }}
              >
                IRDAI
              </Text> */}{' '}
              as per the File &amp; Use Guidelines. For more details on risk factors, terms and
              conditions, please read the sales brochure of respective insurers carefully before
              concluding a sale. Tax benefits are subject to changes in applicable tax laws. You
              authorise Zopper Insurance Brokers Pt Ltd (Zopperinsurance) to contact you through
              call, SMS, email, WhatsApp or any other mode in the future. You hereby override your
              NDNC registration.
            </Text>
          </Flex>
        </Flex>
        {/* <Flex bgColor='#1C1F3E' paddingTop='4'>
          <Flex
            direction='column'
            alignItems='center'
            color='white'
            paddingX='12'
            paddingY='4'
            justifyContent='space-evenly'
            width='100%'
          >
            <div className='flex justify-around xl:flex-row lg:flex-row flex-col text-xs w-[100%]'>
              <Flex direction='column'>
                <Image src={Footer} height='auto' width='150px' marginBottom='2' />
                <Text className='xs:my-4'>
                  Zopper Insurance Brokers Pvt. Ltd. is an Insurance
                  <br /> Broking firm licensed by IRDAI.
                </Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  QUICK LINK
                </Text>
                <Text
                  cursor='pointer'
                  onClick={() => {
                    window.scrollTo(0, 0)
                    navigate('/privacy-policy')
                  }}
                >
                  Privacy Policies
                </Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  REGISTERED OFFICE ADDRESS
                </Text>
                <Text>
                  101, Pratap Nagar, Mayur Vihar,
                  <br /> Phase 1, New Delhi- 110091
                </Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  CORPORATE OFFICE ADDRESS
                </Text>
                <Text>
                  7th Floor, BGR Tower, Plot No.1,
                  <br /> Sector 16 A, Film City, Noida- 201301
                </Text>
              </Flex>
              <Flex direction='column' className='xs:my-2'>
                <Text fontWeight='semibold' marginBottom='4'>
                  CONTACT US
                </Text>
                <Text marginBottom='2'>
                  <EmailIcon /> help@zopperinsurance.com
                </Text>
              </Flex>
            </div>
            <Divider orientation='horizontal' bgColor='white' marginY='4' width='93%' />
            <Text
              textAlign='center'
              fontSize='12px'
              lineHeight='16px'
              marginBottom='8'
              marginTop='2'
            >
              This site is owned and operated by Zopper Insurance Brokers Private Limited [“ZIBPL”
              (CIN:U66000DL2020PTC373851)], an <br />{' '}
              <Text
                as='span'
                color='#E95F2B'
                cursor='pointer'
                _hover={{ textDecoration: 'underline' }}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate('/irdai')
                }}
              >
                IRDAI licensed
              </Text>{' '}
              Direct Broker bearing License No. 790, License Code: DB 880/2021, license dated
              24.12.2021 valid till 23.12.2024.
            </Text>
          </Flex>
        </Flex> */}
        <Text
          bgColor='#393939'
          color='white'
          textAlign='center'
          paddingY='3'
          fontSize='12px'
          lineHeight='16px'
        >
          <Text as='span' fontWeight='semibold'>
            Disclaimer
          </Text>{' '}
          : Insurance is the subject matter of solicitation. For more details on risk factors, terms
          and conditions, please read the sales brochure carefully before concluding a sale.
        </Text>
      </Flex>
    </>
  )
}

export { Home2 }
