import { Header } from 'Components'
import React, { useEffect } from 'react'
import { Flex, Text, Image, Divider, UnorderedList, ListItem } from '@chakra-ui/react'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(1, 1)
  }, [])
  const navigate = useNavigate()

  return (
    <div>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Privacy Policy
        </Text>
        <Text>
          <Text fontWeight={'medium'} as='span'>
            Zopper Insurance Brokers Private Limited{' '}
          </Text>
          as mentioned in this privacy policy{' '}
          <Text fontWeight={'medium'} as='span'>
            (&apos;Privacy Policy&apos;)
          </Text>{' '}
          (hereinafter referred to as{' '}
          <Text fontWeight={'medium'} as='span'>
            &quot;Zopper&quot;
          </Text>
          ) is concerned about the privacy of the data and information of the users accessing and
          availing services on any of Zopper websites including without limitation
          &apos;www.zopperinsurance.com&apos;, mobile sites, mobile applications or chrome extension
          or plugins thereof accessible through various internet enabled smart devices(individually
          and collectively referred to as &quot;Platform&quot;) or otherwise doing business with
          Zopper. This Privacy Policy applies to Zopper and helps you understand how we collect,
          use, store, process, transfer, share and otherwise deal with and protect all your
          information when you visit any of our Platform and avail our services or even otherwise
          visit the Platform. THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF ELECTRONIC
          CONTRACT IN TERMS OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER (AS
          AMENDED FROM TIME TO TIME) AND DOES NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL. The term
          &quot;We&quot;/ &quot;Us&quot; / &quot;Our&quot; used in this document refer to Zopper and
          &quot;You&quot; / &quot;Your&quot; / &quot;Yourself&quot; refer to the users, who visit or
          access or use (collectively &quot;usage&quot;) Platform.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          TERMS AND CONDITIONS:
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Acknowledgment:
        </Text>
        <Text>
          <Text marginBottom={2}>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOU INDICATE THAT YOU UNDERSTAND, AGREE AND
            CONSENT TO THIS PRIVACY POLICY.HENCE BY VISITING OUR PLATFORM OR BY AVAILING ANY OF OUR
            SERVICE, YOU HEREBY GIVE YOUR UNCONDITIONAL CONSENT OR AGREEMENT TO ZOPPER AS PROVIDED
            UNDER SECTION 43A AND SECTION 72A OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 FOR
            COLLECTION, USE, STORAGE, PROCESSING, SHARING AND TRANSFER AND DISCLOSURE OF YOUR
            INFORMATION.
          </Text>
          <Text marginBottom={2}>
            YOU ACKNOWLEDGE THAT YOU HAVE ALL LEGAL RIGHTS AND LAWFUL AUTHORITY TO SHARE THE
            INFORMATION WITH ZOPPER. ZOPPER ACKNOWLEDGES THAT BY COLLECTING, SHARING, PROCESSING AND
            TRANSFERRING INFORMATION PROVIDED BY YOU, SHALL NOT CAUSE ANY LOSS OR WRONFUL GAIN TO
            YOU OR ANY OTHER PERSON.
          </Text>

          <Text marginBottom={2}>
            IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS / USE
            OUR PLATFORM OR AVAIL ANY OF OUR SERVICE ON OUR PLATFORM.
          </Text>
        </Text>
        <Text
          fontSize={20}
          fontWeight={'semibold'}
          marginTop={'20px'}
          marginBottom={'8px'}
          textDecoration={'underline'}
        >
          Topics Covered:
        </Text>
        <Text marginBottom={2}>This policy is designed to make you understand:</Text>
        <UnorderedList paddingLeft={5} spacing={1}>
          <ListItem>
            The type of information which You share with Us or which We collect during Your usage of
            our Platform or availing any Services from Our Platform.
          </ListItem>
          <ListItem>
            The purpose of collection, storage, processing and transferring of Your Information by
            Us.
          </ListItem>
          <ListItem>
            Security measures implemented by Us to protect Your Information as mandated by law.
          </ListItem>
          <ListItem>
            Disclosure, sharing and transfer of Your personal information by Us and purpose of such
            disclosure, sharing or transfer.
          </ListItem>
        </UnorderedList>
        <Text marginTop={2}>
          Information may be collected through various ways such through the use of cookies. Zopper
          may store temporary or permanent &quot;cookies&quot; on your computer. &quot;Cookies&quot;
          are files situated on your mobile/ computer/device’s hard disk that assist us in providing
          services. Further, there are certain features of the Website that are available only
          through the use of a &quot;cookie&quot;. These &quot;cookies&quot; may be used whether you
          register on the Website or not. You can erase or choose to block these cookies from your
          computer. You can configure your computer’s browser to alert you when we attempt to send
          you a cookie with an option to accept or refuse the cookie. If you have turned cookies
          off, you may be prevented from using certain features of the Website.
        </Text>
        <Text
          fontSize={20}
          fontWeight={'semibold'}
          marginTop={'20px'}
          marginBottom={'8px'}
          textDecoration={'underline'}
        >
          Controller of Personal Information
        </Text>
        <Text>
          Zopper will be the controller of Your Personal Information provided by You or otherwise
          collected by Us. Your data controller is responsible for the collection, use, disclosure,
          retention, and protection of your Personal Information in accordance with its privacy
          standards as well as any applicable laws.
        </Text>
        <Text
          fontSize={20}
          fontWeight={'semibold'}
          marginTop={'20px'}
          marginBottom={'8px'}
          textDecoration={'underline'}
        >
          Information We Collect (Your Information):
        </Text>
        <Text>
          We collect Your information during Your usage of Platform or when You avail any services
          available on or through Platform, either as a registered user or otherwise when you visit
          any other website pages or other mobile applications The information collected may consist
          of:
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Your Personal Information:
            <br />
            Your full name, age, address, email id, phone number, date of birth, gender, financial
            information, any other sensitive personal data or information etc. We collect Your
            Personal Information when You create an account at Our Platform or fill out a form or
            respond to a survey.
          </ListItem>
          <ListItem>
            Your Non-Personal information:
            <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
              <ListItem>
                Usage: How You use Our Platform, such as the types of content that you view or
                engage with, the features You use, Your searches & results, Your browsing
                information, the actions You take and the time, frequency and duration of your
                activities.
              </ListItem>
              <ListItem>
                Device Information: Information about the computer, mobile, laptop, tablet or any
                other internet enabled electronic device (‘Device”) You use to access the Platform.
                This may include real - time information about the geographic location of Your
                Device as permitted by You, internet connection, Your IP address, operating systems,
                platforms, browsing information, Device type, Device ID, network information,
                metadata and other information associated with other files stored on Your Device,
                last URL visited, your website search history.
              </ListItem>
              <ListItem>
                Preference: Your preferences and setting such as geographical location, time zone
                and language.
              </ListItem>
              <ListItem>
                Information from third parties: We may collect, process and store Your user ID
                associated with any social media account (such as your Facebook and Google account)
                that You use to sign into the Platform or connect with or use with the services.
                When You sign in to Your account with Your social media account information, or
                otherwise connect to Your social media account with the Services, You consent to Our
                collection, storage, and use, in accordance with this Privacy Policy, of the
                information that You make available to Us through the social media interface. This
                could include, without limitation, any information that You have made public through
                Your social media account, information that the social media service shares with Us,
                or information that is disclosed during the sign-in process. Please see Your social
                media provider&apos;s privacy policy and help centre for more information about how
                they share information when You choose to connect Your account.
              </ListItem>
              <ListItem>
                Information about Your transactions with us, or previous insurers, such as Your
                policy coverage, claim information, premium and payment history.
              </ListItem>
              <ListItem>
                Other information as may be required by the insurance companies or provided by You
                on insurance applications or questionnaire.
              </ListItem>
              <ListItem>
                Cookies and other Electronic Tools: We may use cookies, pixel tags, web beacons,
                mobile device IDs, &apos;flash cookies&apos; and similar files or technologies to
                collect and store information in respect to Your use of the Platform. You can erase
                or choose to disable / block these cookies through Your Device / browser settings.
                You can configure Your browser to alert You when we attempt to send You a cookie
                with an option to accept or refuse the cookie. If You have turned cookies off, You
                may be prevented from using certain features of the Platform and this may interfere
                with certain functionality of the Platform.
              </ListItem>
              <ListItem>
                Browser Add-on/Extension: Add-on or Extensions are software programs that can modify
                and/or enhance the functionality of browsers. You can add extensions to your
                browser(Chrome, Firefox etc.) for a more personalised experience. Add - on /
                Extension can access Your data on all the websites You visit, the tabs and the
                browsing activity of the browser. Add - on / Extension is stored locally to provide
                a better user experience for You. We would capture Your email address so as to
                notify You with relevant information. We would also capture other details to provide
                You a better user experience. When You add Us to Google Chrome as Chrome Extension,
                You explicitly permit Us as well as third parties, to the extent apply, to display
                notifications, read and change Your browsing history, read and change Your
                bookmarks, manage Your apps, extensions and themes, detect Your physical locations,
                read all Your data on the website and other changes
              </ListItem>
              <ListItem>
                Deep Linking - You shall not deep link any pages available on website of Zopper. In
                case of Deep linking of any page on the website that will treated as Intellectual
                Property Right infringement including but not limited to Copyright and Trademark.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Communication:
        </Text>
        <Text>
          We may contact You through email, mobile phone, notices posted on Our Platform and other
          ways including but not limited to text messages and push notifications or any other mode.
          We may send You messages or emails about the availability of Our services, notifications,
          promotional messages, security, or other service - related issues. You hereby override
          your NDNC registration. You may change Your communication preferences at any time.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Manner of storage of Your Information:
        </Text>
        <Text>
          Your Information will mostly be stored in electronic form however certain data may be
          stored in physical form. We shall store, collect, use and process Your Information within
          Republic of India subject to compliance under applicable laws. We may enter into
          agreements with third parties within India to store or process Your Information and such
          third parties may have their own security measures to safeguard Your Information which
          security standards as comparable with good industry practices.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Purpose of Collection, Storage, Processing of Your Information:
        </Text>
        <Text>
          Zopper collect Your Information solely for the purpose of providing you the services that
          is connected with the function or activity of the Zopper which includes but not limited to
          the following (&quot;Purpose&quot;):
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            To inform You about various insurance products and services offered on Our Platform and
            provide customer support to You.
          </ListItem>
          <ListItem>
            To address Your queries and resolve your concerns pertaining to any insurance product or
            service that You were looking for.
          </ListItem>
          <ListItem>
            To send facilitate various programmes and initiatives launched either by Us or third
            party which We believe may be of interest to You.
          </ListItem>
          <ListItem>
            To facilitate Your usage of Platform (what features You like, how You use the, etc.) and
            improve Our services, or any other content on the Platform(such as by personalizing
            content to your interests), process and complete your transactions, and make special
            offers.
          </ListItem>
          <ListItem>
            To review Platform performance, diagnose technical problems and do data analysis to make
            Your experience better.
          </ListItem>
          <ListItem>To protect the integrity of Our Platform.</ListItem>
          <ListItem>
            To inform You about change in any term and condition of this Privacy Policy or Terms of
            Use of any Platform of Zopper.
          </ListItem>
          <ListItem>
            To implement information security practices, to determine any security breaches,
            contaminant or computer virus, to investigate / prevent / take action against illegal
            activities and suspected fraud.
          </ListItem>
          <ListItem>
            To keep You informed about news, events and ideas regarding Your preferences and help
            You to improve Your knowledge and skills about the preferred content.
          </ListItem>
          <ListItem>
            To carry out Our obligations and enforce Our rights arising from any contracts entered
            into between You and Us.
          </ListItem>
          <ListItem>
            To allow You to participate in interactive features offered through Our Platform.
          </ListItem>
          <ListItem>
            To contact You about Our services that may be of interest to You. If You do not want Us
            to use Your Information in this way, please adjust Your user preferences in Your account
            profile.
          </ListItem>
          <ListItem>
            To produce and share aggregate insights and statistics that do not identify You.
          </ListItem>
          <ListItem>For any other purpose with Your consent.</ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Sharing, Transfer or Disclosure of Your Information:
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            Any service provided through Our Platform may be provided by Zopper to render the
            services through Platform or otherwise. You acknowledge and agree that Zopper may share,
            disclose, transfer or part with Your Information to Other Entities depending upon the
            type of Service You are looking for or for one or more of the permissible purposes.
            Zopper may also share Your Personal or Non - Personal information where it is required
            to share the same with such Other Entities for providing you the service and You provide
            Zopper, Your unconditional consent to do the same.
          </ListItem>
          <ListItem>
            Zopper may share statistical data and/or other Non-personal Information or details to
            facilitate various program or initiatives launched by Zopper, third party service
            providers, partners or financial institutions, from time to time
          </ListItem>
          <ListItem>
            We use other third parties such as a credit/debit card processing company, payment
            gateway, pre-paid cards etc. to enable You to make payments for availing services on
            Platform. In such case, We may share Your relevant Information as necessary for the
            third parties to provide such services, including your name, residence and email
            address. The processing of payments or authorization is solely in accordance with these
            third parties policies, terms and conditions and we are not in any manner responsible or
            liable to You or any third party for any delay or failure at their end in processing the
            payments.
          </ListItem>
          <ListItem>
            To facilitate Your usage of Platform (what features You like, how You use the, etc.) and
            improve Our services, or any other content on the Platform(such as by personalizing
            content to your interests), process and complete your transactions, and make special
            offers.
          </ListItem>
          <ListItem>
            In addition to this, Zopper reserve the right to share Your Information with any
            Government Agency or other authorized law enforcement agencies(LEAs) mandated under law
            to obtain Your Information for the purpose of verification of identity or for
            prevention, detection, investigation including but not limited to cyber incidents,
            prosecution, and punishment of offences etc.
          </ListItem>
          <ListItem>
            We may process Your Information outside India and relay on legally provided mechanisms
            to lawfully transfer Your Information across borders.
          </ListItem>
          <ListItem>
            We may share Your Information in any other circumstances where We have Your consent.
          </ListItem>
          <ListItem>
            You hereby grant unconditional and unequivocal consent and authorize Zopper while acting
            on Your behalf for facilitating the transaction on the Platform, to make use of Your
            vehicle registration number or any other vehicle details as submitted by You on the
            Platform, to retrieve the information in relation to your motor vehicle or to confirm /
            verify respective vehicle details from government or third party websites and
            applications through use of technological solutions or any other method itself or
            through third party service providers.
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Security Measures and Force Majeure:
        </Text>
        <Text marginBottom={2}>
          Zopper takes utmost care to secure Your Information from unauthorised access, usage or
          disclosure or alteration. We take appropriate steps and security measures to safeguard
          Your Information and make sure that Your Information is secured as mandated by law. For
          this purpose we adopt reasonable security practices and procedures, in line with the
          industry standard, to include, technical, operational, managerial and physical security
          controls in order to protect your personal information from unauthorized access, or
          disclosure while it is under our control.
        </Text>
        <Text marginBottom={2}>
          While We protect Your Personal information as per industry standards, You acknowledge that
          the internet or computer networks are not fully secure and that We cannot provide any
          absolute assurance regarding the security of Your Personal information. Therefore You
          acknowledge that You shall not hold responsible Zopper in any manner whatsoever for loss
          of Your Information, or any other data, which You shares with Us or which We have with Us
          if such loss or any damage caused to You because of Force Majeure events. You further
          acknowledge that any information (including your personal information) transmitted to Us
          or from Your use of Platform will therefore be at Your own risk.
        </Text>
        <Text>
          It is clarified that Force Majeure events includes any event which is beyond the
          reasonable control of the Zopper which may include but not limited to sabotage, fire,
          flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind,
          riots, insurrection, war, acts of government, computer hacking, unauthorised access to
          computer, computer system or computer network, computer crashes, breach of security and
          encryption.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Your Choices to Control Your Personal Information
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Change or Correct:
            </Text>{' '}
            We take all endeavours to update Our records with latest information as provided by You,
            however if you see any discrepancy in Your Personal Information, You may edit some of
            Your Personal Information or contact Us through Our Data Protection Officer / Grievance
            Officer to have Your Information updated with Us.
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Object to, or Limit or Restrict, Use:
            </Text>{' '}
            You can ask Us to stop using or to limit Our use (in partial or full) Your Personal
            Information (past, existing or future).
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Deletion:
            </Text>{' '}
            You can ask Us to erase or delete (in partial or full) Your Personal Information (past,
            existing or future).
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Right to Access:
            </Text>{' '}
            You can ask Us for a copy of Your Personal Information.
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Account Closure:
            </Text>{' '}
            If You choose to close Your Account, We will delete your Personal Information or
            de-identify it so that it is anonymous and not attributed to your identity. We will
            retain your Personal Information after You have closed Your account if reasonably
            necessary to comply with Our legal obligations(including law enforcement requests), meet
            regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse,
            enforce Our User Agreement, or fulfil Your request to “unsubscribe” from further
            messages from Us. We will retain de - personalized information after your account has
            been closed.
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'} as='span'>
              Withdraw Consent/Opt-out:
            </Text>{' '}
            At any time, you can withdraw consent that You have provided to Us by going to your
            account settings, if you have an account with us or otherwise you may request for
            Withdrawing your consent by writing an email to us at care@zopperinsurance.com.We will
            only collect and process Personal Information about You where We have lawful bases.
            Lawful bases include consent (where You have given consent), contract (where processing
            is necessary for the performance of a contract with You(e.g.to provide services you have
            requested)) and “legitimate interests”. Where We rely on Your consent to process
            Personal Information, you have the right to withdraw or decline Your consent at any time
            and where we rely on legitimate interests, you have the right to object.
          </ListItem>
          <Text>
            You may also contact Us using the contact information below, and We will consider Your
            request in accordance with applicable laws.
          </Text>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'8px'}>
          Change in terms of Privacy Policy:
        </Text>
        <Text marginTop={2}>
          Zopper reserves the right to amend or modify this Privacy Policy at any time, as and when
          the need arises. We request you to regularly check this Privacy Policy from time to time
          to keep you apprise of changes made. Your continued use of the Platform gives your
          unconditional acceptance to such change in terms of Privacy Policy.
        </Text>
        <Text marginTop={2}>
          Particulars of corporate entity, Data Protection Officer/Grievance Officer pursuant to the
          provisions of Companies Act, 2013 and The Indian Information Technology Act, 2000 and
          rules made and applicable laws thereunder:
        </Text>
        <Text marginTop={2}>
          If you have any concern related to this Privacy Policy or collection, storage, retention
          or disclosure of Your Information under the terms of this Privacy Policy or Terms of Use
          or any other terms and conditions of Zopper or its other business entities including any
          queries or grievances, You can contact to Zopper through its Data Protection Officer /
          Grievance Redressal Officer at the below given details:
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>Name: Mr Rajeev Kumar Rout</ListItem>
          <ListItem>
            Address: Grievance Officer, Corporate Office – 7th Floor, Plot no 1, BGR Tower, Sector
            16 A, NOIDA U.P. 201301
          </ListItem>
          <ListItem>E-mail: care@zopperinsurance.com</ListItem>
          <ListItem>Telephone Number: 7669248641</ListItem>
          <ListItem>Working Days: Monday to Friday</ListItem>
          <ListItem>Working Hours: 10:30 am to 6:30 pm</ListItem>
        </UnorderedList>
        <Text marginTop={2}>
          We will strive to address your feedback and concerns in a timely and effective manner.
          Please note that the details of the Data Protection Officer / Grievance Officer may be
          changed by Us from time to time by updating this Privacy Policy.
        </Text>
      </Flex>

      <Footer />
    </div>
  )
}

export default PrivacyPolicy
