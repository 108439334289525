import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'
import { Flex, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react'

export default function GrievancePolicy() {
  return (
    <div>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Grievance Redressal Policy
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          INTRODUCTION
        </Text>
        <Text>
          {/* Zopper Insurance Brokers Private Limited (hereinafter referred as “The Company/ZIBPL”)
          believes that excellence in customer service is the most important tool for sustained
          business growth. Therefore, the company follows a philosophy of providing resolution of
          the customers&apos; complaint/grievance in a manner that effectively resolves the
          complaint to customer&apos;s satisfaction by adhering to the applicable IRDAI Regulations,
          Guidelines etc. */}
          Zopper Insurance Brokers Private Limited (hereinafter referred as “The Company/ZIBPL”)
          believes that excellence in customer service is the most important tool for sustained
          business growth. Therefore, the company follows a philosophy of providing resolution of
          the customers&apos; complaint/grievance in a manner that effectively resolves the
          complaint to customer&apos;s satisfaction by adhering to the applicable IRDAI Regulations,
          Guidelines etc.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          OBJECTIVE
        </Text>
        <Text>
          The objective of this policy is to provide efficient and effective grievance redressal
          mechanism to policyholders, nominees and other persons claiming under policies and has
          been formulated taking into account the following:
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>Complaints raised by customers are dealt with courtesy and on time.</ListItem>
          <ListItem>Customers are treated fairly at all times.</ListItem>
          <ListItem>Complete transparency is maintained with the customers.</ListItem>
          <ListItem>All complaints are dealt with efficiency and fairness.</ListItem>
          <ListItem>
            Customers are fully informed about avenues to escalate their complaints / grievances
            within the organization.
          </ListItem>
          <ListItem>
            Customers are informed about their rights to alternative remedy if they are not fully
            satisfied with the response of the Company to their complaints.
          </ListItem>
          <ListItem>
            Recognize that our quality and business goals go hand-in-hand and have a continual
            improvement of the customer complaint handling process through the use of various tools
            and information technology available for business process improvement.
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          SCOPE
        </Text>
        <Text>
          The policy shall cover all the complaints/grievances received from the policy holder/ its
          nominee/beneficiary/authorized person (with the written consent of the policy owner). The
          company will not accept any complaint from third party, agencies on behalf of the customer
          unless we have written consent from the policy holder.
        </Text>
        <Text marginTop={2}>
          Grievances received from consumer forums or ombudsman office or court will be dealt
          separately by the legal team.
        </Text>
        <Text marginTop={2}>Inquiry or Request are not covered under this policy.</Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          DEFINITIONS
        </Text>
        <Text>
          “Complainant” means a policyholder or prospect or nominee or assignee or any beneficiary
          of an insurance policy who has filed a complaint or grievance against the insurer or the
          company
        </Text>
        <Text marginTop={2}>
          “Complaint” or “Grievance” means written expression (includes a communication in the form
          of electronic mail or other electronic scripts), if dissatisfaction by a complainant with
          respect to solicitation or sale or purchase of an insurance policy or related services by
          insurer and /or by the Company.
          {/* with
          insurer, company or other regulated entities about an action or lack of action about the
          standard of service or deficiency of service of such insurer, company or other regulated
          entities */}
        </Text>
        <Text marginTop={2}>
          Explanation - An inquiry or request would not fall within the definition of the complaint
          or grievance.
        </Text>
        <Text marginTop={2}>An Inquiry and Request would mean the following:</Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            An “Inquiry” is defined as any communication from a customer for the primary purpose of
            requesting information about a company and/or its services.
          </ListItem>
          <ListItem>
            A “Request” is defined as any communication from a customer soliciting a service such as
            a change or modification in the policy
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          COMPLAINT REDRESSAL PROCESS
        </Text>
        <Text>
          If you have a grievance that you wish to redress, you may contact us with the details of
          your grievance through any of the following channels:
        </Text>
        <Text fontWeight={'medium'} marginTop={2}>
          Step 1: Channel for communication
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Email:{' '}
            <Link as='a' href='mailto:care@zopperinsurance.com' color='blue.500'>
              care@zopperinsurance.com
            </Link>{' '}
            or
          </ListItem>
          <ListItem>
            Letter: Grievance Officer, Corporate Office - 7th Floor, Plot no 1, BGR Tower, Sector 16
            A, NOIDA U.P. 201301. Or
          </ListItem>
          <ListItem>
            Contact Centre: Customer can call us on 7669248641 from Monday to Friday (excluding
            public holidays) between 10:30 am to 6:30 pm
          </ListItem>
        </UnorderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          Step 2: Process for addressing the queries
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            All grievances will be given acknowledgment receipt within 24 working hours of the
            receipt of complaint.
          </ListItem>
          <ListItem>
            All complaints will be answered within 14 days from the date of receipt.
          </ListItem>
          <ListItem>
            All grievances from walk in customer will be acknowledged immediately and log shall be
            maintained in this regard.
          </ListItem>
          <ListItem>
            Based on type of grievance, the company shall exercise all efforts to resolve the same
            within 14 working days from the date of receipt of complaint.
          </ListItem>
          <ListItem>
            Once the complaint is resolved, a closure mail shall be sent to the customer with the
            request of rating the same.
          </ListItem>
        </UnorderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          Step 3: Escalation Matrix
        </Text>
        <Text marginTop={2}>
          If a client is not satisfied with the resolution provided through various channels, the
          client has the option to escalate the issues to a higher level, as per the escalation
          matrix given underneath
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          <ListItem>
            Level 1: In case the customer is not satisfied with the decision or has not received any
            response within 14 working days, he/she may escalate the matter to Grievance Redressal
            Officer, Mr Rajeev Kumar Rout at rajeev.rout@zopperinsurance.com.
          </ListItem>
          <ListItem>
            Level 2: If still not satisfied after level 1, please escalate the matter to the
            Principal Officer at Po@zopperinsurance.com.
          </ListItem>
          <ListItem>
            Level 3: If after having followed Level 1 and Level 2 if issue remains unsolved, client
            may approach The Insurance Regulatory Development Authority of India (IRDAI) at
            http://www.policyholder.gov.in/report.aspx# or{' '}
            <Link href='https://bimabharosa.irdai.gov.in/' isExternal color={'blue.500'}>
              https://bimabharosa.irdai.gov.in/
            </Link>{' '}
            (earlier known as IGMS) or Ombudsman at https://cioins.co.in/Complaint/Online
            respectively, The maximum limit for the amount under dispute for which the Ombudsman can
            entertain a complaint is upto Rs. 50 lakhs.
          </ListItem>
        </UnorderedList>
        <Text fontWeight={'medium'} marginTop={2}>
          Step 4: Resolution of Grievances
        </Text>
        <Text marginTop={2}>
          ZIBPL endeavours to resolve all grievances to the satisfaction of the customers. In order
          to ensure fair resolution for the customer, the Regulator has set conditions for treating
          the grievances as closed. As per IRDAI regulations, a grievance shall be considered as
          disposed-off and resolved:
        </Text>
        <UnorderedList paddingLeft={5} spacing={1} marginTop={2}>
          {/* <ListItem>When ZIBPL has acceded to the request of the complainant fully. Or</ListItem> */}
          <ListItem>
            Where the complainant has indicated in writing, acceptance of the response of the
            company. Or
          </ListItem>
          <ListItem>
            The complaint shall be deemed to be closed, in case the complainant does not respond
            within 8 weeks of the Company&apos;s written response.
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={'20px'} marginBottom={'10px'}>
          REVIEW
        </Text>
        <Text marginTop={2}>This policy will be reviewed as and when required.</Text>
      </Flex>

      <Footer />
    </div>
  )
}
