import { Flex, Link, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react'
import { Header } from 'Components'
import React from 'react'
import Footer from '../footer'

export default function EInsuranceAccount() {
  return (
    <Flex minH={'100vh'} flexDir={'column'}>
      <Header />
      <Flex paddingX={[5, 10, 20]} flexDir={'column'} paddingY={10}>
        <Text fontSize={24} fontWeight={'semibold'} marginTop={1} marginBottom={'8px'}>
          Everything You Should Know About e-Insurance Accounts
        </Text>
        <Text>
          Wondering how to maintain all your insurance policies in one place? No doubt, it’s tough
          to maintain insurance policy papers (be it health insurance, life insurance, general
          insurance, etc.) of all family members with different covers. But, you can make it easy.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          What is an E-Insurance Account?
        </Text>
        <Text>An e-insurance account is a solution for you.</Text>
        <Text marginTop={2}>Let me explain what E-insurance account is?</Text>
        <Text marginTop={2}>
          “An E-insurance Account or Electronic Insurance Account or eIA is like a depository to
          keep your insurance policies in the electronic mode. It enables you to maintain documents
          of all your or your family’s life insurance, general insurance, health policies etc in one
          place.” With an e-insurance account, you can access your insurance portfolio at a few
          clicks. This saves you from unnecessary paperwork, and safeguards in case you lose your
          policies. The best part is that you can access insurance policies anywhere, anytime in
          seconds without any restriction.
        </Text>
        <Text marginTop={2}>
          Let’s discuss how you can open an e-insurance account. We will also try to know its
          features and benefits.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Procedure for Opening an E-Insurance Account
        </Text>
        <Text marginTop={2}>
          Opening an e-insurance account is not hard. You can open it with any one of the four
          insurance repositories approved by IRDAI or through your insurance company.
        </Text>
        <Text marginTop={2}>
          There are four entities that have got the ‘Certificate of Registration’ from IRDAI to act
          as ‘Insurance Repositories.’ These entities are:
        </Text>
        <OrderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            <Link as='a' href='https://www.cirl.co.in/' color='blue.500' isExternal>
              CDSL Insurance Repository Limited
            </Link>
          </ListItem>
          <ListItem>
            <Link
              as='a'
              href='https://nir.ndml.in/registration-for-nir.htm'
              color='blue.500'
              isExternal
            >
              NSDL National Insurance Repository (NIR)
            </Link>
          </ListItem>
          <ListItem>
            <Link
              as='a'
              href='https://www.camsrepository.com/CR_Registration.aspx'
              color='blue.500'
              isExternal
            >
              CAMS Repository Services Limited
            </Link>
          </ListItem>
          <ListItem>
            <Link as='a' href='https://www.kinrep.com/' color='blue.500' isExternal>
              Karvy Insurance Repository Limited
            </Link>
          </ListItem>
        </OrderedList>
        <Text marginTop={2}>
          These entities are authorized to open e-Insurance Accounts. Here are five easy steps to
          open an account.
        </Text>
        <OrderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>Choose your preferred Insurance Repository</ListItem>
          <ListItem>Download the eIA opening form</ListItem>
          <ListItem>Fill up this form.</ListItem>
          <ListItem>
            Attach self-attested KYC documents, which include copies of identity proof, date of
            birth proof, and address proof.
          </ListItem>
          <ListItem>
            Provide personal details, bank details (a cancelled cheque), and contact details.
          </ListItem>
          <ListItem>A recent passport size photograph should also be affixed to the form.</ListItem>
          <ListItem>Once you’re done, submit the form to your insurance company</ListItem>
        </OrderedList>
        <Text marginTop={2}>
          Note: KYC documents are not required when opening the account through an insurance
          provider. The insurer will send your KYC details to the repository.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Who is an Authorized Representative?
        </Text>
        <Text marginTop={2}>
          An authorized representative is the person who is appointed by the account holder. Only a
          person more than age of 21 days shall be eligible to be appointed as an Authorised
          Representative. In the absence or the demise of the account holder or if he or she is
          incapable of accessing the account, the authorized person can access the e-insurance
          account.
        </Text>
        <Text marginTop={2}>
          Therefore, it is a must for you to mention the contact information of an authorized
          representative.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Documents needed for Opening an E-Insurance Account
        </Text>
        <Text marginTop={2}>
          The following documents needs to be submitted by you in order to open an e-insurance
          account:
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            A cancelled bank account cheque, consisting of your bank account number and other
            relevant bank details.
          </ListItem>
          <ListItem>A latest passport size coloured photograph</ListItem>
          <ListItem>
            A self attested identity proof copy such as your pan card, Voter ID card, passport, etc.
          </ListItem>
          <ListItem>
            Copy of age proof( should be self-attested) such as voter id card, passport, birth
            certificate etc.
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Things to Know about E-Insurance
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            It is essential to know that there is no charge for opening an e-insurance account.
          </ListItem>
          <ListItem>
            The authorized representative you choose can only access the e-insurance account to know
            the portfolio of insurance policies.
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Features of E-Insurance Accounts
        </Text>
        <Text marginTop={2}>
          There are some features of an e-insurance account. Here are some of them.
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>You can hold only one e-insurance account.</ListItem>
          <ListItem>
            You can keep all the policies, even from multiple insurers, in a single account.
          </ListItem>
          <ListItem>
            All electronic insurance policies, be it a pension, life, health or general, can be
            stored in the same account.
          </ListItem>
          <ListItem>
            Once you open an account, you will get a unique number for future assistance and
            queries.
          </ListItem>
          <ListItem>You will also get a unique login ID and password.</ListItem>
        </UnorderedList>
        <Text marginTop={2}>
          Note: When you buy an online health insurance, you need to just insert your unique
          e-insurance account number in your proposal form and then send it to the insurance
          provider with a request to issue the policy in an electronic format.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Benefits of E-Insurance Accounts
        </Text>
        <Text marginTop={2}>
          The benefits of an e-insurance account are manifold. The account plays an effective role,
          especially if different types of insurance policies are purchased from different insurers.
          Below are some of the benefits:
        </Text>
        <UnorderedList paddingLeft={5} spacing={2} marginTop={2}>
          <ListItem>
            <Text fontWeight={'medium'}>Protection and Safety</Text>
            <Text>
              When you store your policies online, they remain safe and secure. You need not worry
              about losing them. Also, there is nothing to worry about the damage of the original
              policy documents, as they will be available in a secure electronic format.
            </Text>
            <Text fontWeight={'medium'} marginTop={2}>
              Single Consolidated View
            </Text>
            <Text>
              The e-insurance features a ‘single consolidated view.’ That means you can view all the
              documents online using several devices and track a variety of insurance policies.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>No Need to Resubmit KYC Details</Text>
            <Text>
              Once you submit KYC documents and hold an e-insurance account, you don’t have to
              resubmit the KYC details for buying new insurance policies in the future. Instead,
              just mention the online insurance account number and the policy will be issued to you.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>Paperless Environment</Text>
            <Text>
              One of the most crucial benefits is the paperless environment. When you opt for the
              electronic mode, you save papers and promote an environment-friendly atmosphere.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>Better Monitoring</Text>
            <Text>
              The e-account enables you to receive alerts for policy renewal and premium deadline
              reminders. Thus, you can monitor your policies in a better way and avoid any unwanted
              policy lapses.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>User-friendly</Text>
            <Text>
              One e-insurance account is sufficient to purchase different insurance plans from a
              variety of insurers. Besides, you can access these policies anywhere, anytime at your
              convenience.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>Easy Premium Payments</Text>
            <Text>
              Easy premium payment is another benefit of e-insurance accounts. The e-insurance
              enables you to pay your premium online after the e-account becomes active.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>Easy Service</Text>
            <Text>
              As an account holder, you can easily make changes in contact details, addresses, etc.
              for all policies. Besides, you can change the insurance repository on being
              dissatisfied with the services.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>No Insurance? No Worry</Text>
            <Text>
              Even if you have no insurance policy, you are eligible to open e-insurance account.
              So, don’t worry even if you have not bought any Insurance policy. You can still open
              an e-insurance account.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight={'medium'}>Easy to Convert Old Policies</Text>
            <Text>
              If you have an old policy, you can easily convert it into an e-policy and store in
              your e-insurance account without facing any problem.
            </Text>
          </ListItem>
        </UnorderedList>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          How to Opt out of the Insurance Repository?
        </Text>
        <Text marginTop={2}>
          In case, you wish to drop out from the e-insurance repository, then you are required to
          submit this request in writing to the respective insurance provider. After making all the
          required changes, the insurance provider within 5 working days will provide an intimation
          receipt. Once all the conditions are met and payment is made, a hard copy will be provided
          to you by the company stating you have opted out.
        </Text>
        <Text marginTop={2}>Over to You</Text>
        <Text marginTop={2}>
          You’re now aware of all the benefits of an e-insurance account. It’s up to you to opt for
          it or not. However, many people are choosing e-insurance and enjoying its benefits these
          days.
        </Text>
        <Text fontSize={20} fontWeight={'semibold'} marginTop={2} marginBottom={'8px'}>
          Frequently Asked Questions (FAQs)
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q1. How to open an e-insurance account?
        </Text>
        <Text marginTop={2} fontWeight={'medium'}>
          A: Opening an e-insurance account is not at all a difficult task. All you need to do is
          follow the step by step guide given below:
        </Text>
        <Text marginTop={2}>
          a. Download E- Insurance Account opening form of the Insurance Repository you prefer
        </Text>
        <Text marginTop={2}>
          b. Fill the form and attach a self-attested copy of your KYC documents
        </Text>
        <Text marginTop={2}>
          c. Submit the forms with the self-attested documents to the insurance repository
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q2. Who is an approved person?
        </Text>
        <Text marginTop={2}>
          A. An approved person is an individual who has been appointed and authorised by the
          insurance repository to offer services like opening an e-insurance account for the
          interested individuals.
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q3. Can I still open an e-insurance account if I don&apos;t have any insurance plan?
        </Text>
        <Text marginTop={2}>
          A: Yes. Even if you do not have any insurance policy, you can still open an e-insurance
          account as that is not a criterion for the same. So, no matter if you have an insurance
          plan or not, you can still open an e-insurance account.
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q4. Am I allowed to store commercial insurance plans too, in the e-insurance account
        </Text>
        <Text marginTop={2}>
          A: Yes. You are allowed to add even commercial insurance plans in the e-insurance account.
          This is because the account does not have any restriction on the type of insurance plans
          to be stored in them.
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q5. How long will it take for the account to get opened once I submit the documents?
        </Text>
        <Text marginTop={2}>
          A: Once you submit the documents, your e-insurance account will be opened within 7 days
          after submission of application complete in all respects. Once, an account is opened, a
          welcome kit with the details of how to operate the same would be sent to the
          applicant/e-Insurance account holder.
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q6. Can my existing plans be converted to e-policies and stored in my e-insurance account?
        </Text>
        <Text marginTop={2}>
          A: Yes. You can ask your insurance company to convert your existing insurance plans to
          e-insurance plans and store them in your e-insurance account.
        </Text>
        <Text marginTop={2} fontWeight={'bold'}>
          Q7. What will happen to my e-insurance account in case I die?
        </Text>
        <Text marginTop={2}>
          A: When you open an e-insurance account, you will be appointed an authorised
          representative who can access your account if you die. You are required to mention the
          name in your account opening form. If you die, then the authorised representative would be
          able to access your e-insurance account and details of insurance plans stored in it.
        </Text>
      </Flex>
      <Flex marginTop={'auto'}>
        <Footer />
      </Flex>
    </Flex>
  )
}
