import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authenticationApi } from 'Services/API/authentication.api'

// const persistConfig = {
//   key: `STORE_${process.env.REACT_APP_ENV}`,
//   storage: storageSession,
//   blacklist: [supportApis.reducerPath],
// }

const reducers = combineReducers({
  [authenticationApi.reducerPath]: authenticationApi.reducer,
})

// const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: reducers,
  middleware: (gDM) => gDM({ serializableCheck: false }).concat(authenticationApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
