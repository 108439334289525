import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosRequestConfig, AxiosError, AxiosRequestHeaders } from 'axios'
import { FOUNDATION_URL } from 'Constants'
import { IApiResponse } from 'Models/ResponseModels'
import { store } from '../app/store'

export const getDefaultHeaders = (headers?: AxiosRequestHeaders | undefined) => {
  const tempHeaders = {
    'Content-Type': 'application/json',
    Source: 'Web',
    Lang: 'EN',
  }
  // if (token) {
  //   return {
  //     ...tempHeaders,
  //     Authorization: `Token ${token}`,
  //     ...headers,
  //   }
  // }
  return {
    ...tempHeaders,
    ...headers,
  }
}

export const getFileHeaders = (token: string, headers?: AxiosRequestHeaders | undefined) => {
  const tempHeaders = { 'Content-Type': 'multipart/form-data', Source: 'Web', Lang: 'EN' }
  if (token) {
    return {
      ...tempHeaders,
      Authorization: `Token ${token}`,
      ...headers,
    }
  }
  return {
    ...tempHeaders,
    ...headers,
  }
}

export const axiosBaseQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string
  }): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      body?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
      responseType?: AxiosRequestConfig['responseType']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params, headers, responseType }) => {
    // const token = store.getState().user.authenticatedUser.authtoken
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers: getDefaultHeaders(headers),
        responseType,
      })

      if (responseType === 'text') {
        return result
      }
      if (result.data.status.toString() !== '200' && result.data.status.toString() !== '201') {
        if (result.data.message) {
          // toast.error(result.data.message)
          console.log(result.data.message)
        }
        throw result.data
      }
      return { data: result.data.data }
    } catch (axiosError) {
      if (axios.isAxiosError(axiosError)) {
        const err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      } else {
        const err = axiosError as IApiResponse
        return {
          error: {
            status: err.status,
            data: err.data,
            message: err.message,
          },
        }
      }
    }
  }

const foundationUrlQuery = axiosBaseQuery({
  baseUrl: FOUNDATION_URL,
})

export { foundationUrlQuery }
