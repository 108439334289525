import { Flex, Text, Image, Divider } from '@chakra-ui/react'
import { Header } from 'Components'
import React from 'react'
import License from 'Assets/ZIBPL/Licence_Zopper Insurance Brokers.jpg'
import Footer from 'Assets/ZIBPL/footer_logo3x.png'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

const IRDAI = () => {
  const navigate = useNavigate()
  return (
    <>
      <Flex direction='column' height='100vh' justifyContent='space-between'>
        <Header />
        <Flex direction='column' alignItems='center'>
          <Text
            decoration='underline'
            textDecorationColor='#E95F2B'
            marginY='4'
            fontSize='25px'
            fontWeight='semibold'
            textUnderlineOffset='6px'
          >
            <Text as='span' color='#E95F2B'>
              IRDAI
            </Text>{' '}
            License
          </Text>
          <div className='px-10 flex xl:flex-row flex-col items-center justify-evenly'>
            <Flex direction='column' flexBasis='50%' paddingLeft='8'>
              <Text fontSize='25px' fontWeight='bold' marginY='3'>
                Zopper Insurance Brokers Pvt. Ltd.
              </Text>
              <Text marginBottom='3'>
                Zopper Insurance is a direct Insurance Broker approved and licensed by{' '}
                <Text as='span' color='#E95F2B'>
                  IRDAI
                </Text>{' '}
                (Insurance Regulatory and Development Authority)
              </Text>
              <Text>
                <Text as='span' fontWeight='semibold'>
                  License no.
                </Text>
                : 790
              </Text>
              <Text>
                <Text as='span' fontWeight='semibold'>
                  License valid till
                </Text>
                : 23/12/2024
              </Text>
              <Text>
                <Text as='span' fontWeight='semibold'>
                  Principal officer name
                </Text>
                : Mr. Bhushan Sheth
              </Text>
              <Text>
                <Text as='span' fontWeight='semibold'>
                  Email
                </Text>
                : bhushan@zopper.com
              </Text>
            </Flex>
            <Image src={License} width='600px' height='400px' flexBasis='50%' />
          </div>
        </Flex>
        <Flex direction='column'>
          <Flex bgColor='#1C1F3E' paddingTop='4'>
            <Flex
              direction='column'
              alignItems='center'
              color='white'
              paddingY='4'
              justifyContent='space-evenly'
              width='100%'
            >
              <div className='flex justify-around xl:flex-row lg:flex-row flex-col text-xs w-[100%]'>
                <Flex direction='column'>
                  <Image src={Footer} height='auto' width='150px' marginBottom='2' />
                  <Text className='xs:my-4'>
                    Zopper Insurance Brokers Pvt. Ltd. is an Insurance
                    <br /> Broking firm licensed by IRDAI.
                  </Text>
                </Flex>
                <Flex direction='column' className='xs:my-2'>
                  <Text fontWeight='semibold' marginBottom='4'>
                    QUICK LINK
                  </Text>
                  <Text
                    cursor='pointer'
                    onClick={() => {
                      window.scrollTo(0, 0)
                      navigate('/privacy-policy')
                    }}
                  >
                    Privacy Policies
                  </Text>
                </Flex>
                <Flex direction='column' className='xs:my-2'>
                  <Text fontWeight='semibold' marginBottom='4'>
                    REGISTERED OFFICE ADDRESS
                  </Text>
                  <Text>
                    101, Pratap Nagar, Mayur Vihar,
                    <br /> Phase 1, New Delhi- 110091
                  </Text>
                </Flex>
                <Flex direction='column' className='xs:my-2'>
                  <Text fontWeight='semibold' marginBottom='4'>
                    CORPORATE OFFICE ADDRESS
                  </Text>
                  <Text>
                    7th Floor, BGR Tower, Plot No.1,
                    <br /> Sector 16 A, Film City, Noida- 201301
                  </Text>
                </Flex>
                <Flex direction='column' className='xs:my-2'>
                  <Text fontWeight='semibold' marginBottom='4'>
                    CONTACT US
                  </Text>
                  <Text marginBottom='2'>
                    <EmailIcon /> help@zopperinsurance.com
                  </Text>
                </Flex>
              </div>
              <Divider orientation='horizontal' bgColor='white' marginY='4' width='92%' />
              <Text
                textAlign='center'
                fontSize='12px'
                lineHeight='16px'
                marginBottom='8'
                marginTop='2'
              >
                This site is owned and operated by Zopper Insurance Brokers Private Limited [“ZIBPL”
                (CIN:U66000DL2020PTC373851)], an <br />{' '}
                <Text
                  as='span'
                  color='#E95F2B'
                  cursor='pointer'
                  _hover={{ textDecoration: 'underline' }}
                  //   onClick={() => {
                  //     window.scrollTo(0, 0)
                  //     navigate('/irdai')
                  //   }}
                >
                  IRDAI licensed
                </Text>{' '}
                Direct Broker bearing License No. 790, License Code: DB 880/2021, license dated
                24.12.2021 valid till 23.12.2024.
              </Text>
            </Flex>
          </Flex>
          <Text
            bgColor='#393939'
            color='white'
            textAlign='center'
            paddingY='3'
            fontSize='12px'
            lineHeight='16px'
          >
            <Text as='span' fontWeight='semibold'>
              Disclaimer
            </Text>{' '}
            : Insurance is the subject matter of solicitation. For more details on risk factors,
            terms and conditions, please read the sales brochure carefully before concluding a sale.
          </Text>
        </Flex>
      </Flex>
      {/* <Text
        bgColor='#393939'
        color='white'
        textAlign='center'
        paddingY='2'
        fontSize='14px'
        lineHeight='20px'
      >
        <Text as='span' fontWeight='semibold'>
          Disclaimer
        </Text>{' '}
        : Insurance is the subject matter of solicitation. For more details on risk factors, terms
        and conditions, please read the sales brochure carefully before concluding a sale.
      </Text> */}
    </>
  )
}

export { IRDAI }
